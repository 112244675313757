import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {
  makeStyles,
  Button,
  CircularProgress,
  Divider,
  Typography,
  FormControl,
  Backdrop,
  FormHelperText,
} from '@material-ui/core';
import {useFormik} from 'formik';
import documentManagement, {DocumentManagementSheetStatus} from 'api/documentManagement';
import {isError} from 'lib/helper';
import Output from 'components/Common/Output';
import resources from 'api/resources';
import app from 'api/app';
import MultiSelectDropdown from 'components/MultiSelectDropdown';
import {SHEET_GENERATION_POLLING_INTERVAL} from 'lib/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    height: '100%',
    padding: '30px 50px',
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: 500,
    marginBottom: 25,
  },
  submit: {
    padding: '13px 0px',
    textTransform: 'inherit',
    width: '100%',
    fontSize: 15,
    marginTop: 18,
  },
  subTitle: {
    color: '#757575',
    fontStyle: 'italic',
  },
}));

const ALL_OPTION = 'All';

const onSubmitValidator = (selectedAppKey, selectedAuthor) => {
  const errors = {};

  if (selectedAppKey.length && selectedAuthor.length) {
    return errors;
  }

  if (!selectedAppKey.length) {
    errors.appKey = 'Select an app key';
  }

  if (!selectedAuthor.length) {
    errors.author = 'Select an author';
  }

  return errors;
};

export default function GoogleSheetGeneration() {
  const [sheetURL, setSheetURL] = useState('');
  const [error, setError] = useState('');
  const [authors, setAuthors] = useState([]);
  const [appKeys, setAppKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // TODO: Abstract this pattern for reusability in https://pronavtechnologies.atlassian.net/browse/PBP-8424
  useEffect(() => {
    let didCancel = false;

    const fetchAuthorsAndAppKeys = async () => {
      setIsLoading(true);
      setError('');
      setAuthors([]);
      setAppKeys([]);

      try {
        const [authors, appKeys] = await Promise.all([resources.getAuthors(), app.getSageApps()]);

        // Abort if cleanup function ran while fetching. This likely means the component was unmounted.
        if (didCancel) {
          return;
        }

        if (!authors || authors.length === 0) {
          throw new Error('No authors found');
        }

        if (!appKeys || appKeys.length === 0) {
          throw new Error('No app keys found');
        }

        setAuthors(authors);
        setAppKeys(appKeys);
      } catch (error) {
        setError(error.message);
      }

      setIsLoading(false);
    };

    fetchAuthorsAndAppKeys();

    return () => {
      didCancel = true;
    };
  }, []);

  const formattedAppKeys = useMemo(() => {
    const formattedAppKeys = appKeys
        .map((app) => ({
          key: app.app_id,
          value: app.app_id,
          label: app.description,
        }))
        .sort((a, b) => a.value.localeCompare(b.value));

    formattedAppKeys.unshift({key: ALL_OPTION, value: ALL_OPTION, label: ALL_OPTION});
    return formattedAppKeys;
  }, [appKeys]);

  const formattedAuthors = useMemo(() => {
    const formattedAuthors = authors
        .map((author) => ({
          key: author.id.toString(),
          value: author.name,
          label: author.name,
        }))
        .sort((a, b) => a.value.localeCompare(b.value));

    formattedAuthors.unshift({key: ALL_OPTION, value: ALL_OPTION, label: ALL_OPTION});
    return formattedAuthors;
  }, [authors]);

  const onSubmitHandler = async (values, {setSubmitting, setErrors}) => {
    async function sleep(duration) {
      return new Promise((resolve) => setTimeout(resolve, duration));
    }
    async function pollSheetStatus(sheetKey) {
      let status;
      do {
        const sheetData = await documentManagement.getSheet(sheetKey);
        status = sheetData['status'];
        switch (status) {
          case DocumentManagementSheetStatus.GENERATING:
            await sleep(SHEET_GENERATION_POLLING_INTERVAL);
            break;
          case DocumentManagementSheetStatus.ACTIVE:
            setSubmitting(false);
            break;
          default:
            throw new Error('Sheet did not generate properly.');
        }
      } while (status === DocumentManagementSheetStatus.GENERATING);
    }
    try {
      // Clear existing state
      setError('');
      setSheetURL('');

      setSubmitting(true);
      const appKeyToSubmit = selectedAppKey.includes(ALL_OPTION) ? '' : selectedAppKey;
      const authorToSubmit = selectedAuthor.includes(ALL_OPTION) ? '' : selectedAuthor;
      const {sheet_url: sheetURL, sheet_key: sheetKey} = await documentManagement.generateSheet(
          appKeyToSubmit,
          authorToSubmit,
          true,
      );
      await pollSheetStatus(sheetKey);
      setSheetURL(sheetURL);
    } catch (error) {
      setSubmitting(false);
      console.error('error: ', error.message);
      setError(error.message);
    }
  };

  const classes = useStyles();
  const formik = useFormik({
    initialValues: {appKey: [], author: []},
    validate: () => onSubmitValidator(selectedAppKey, selectedAuthor),
    onSubmit: onSubmitHandler,
  });

  const selectedAuthor = useMemo(() => {
    return formik.values.author;
  }, [formik.values.author]);

  const selectedAppKey = useMemo(() => {
    return formik.values.appKey;
  }, [formik.values.appKey]);

  const actions = useMemo(() => {
    if (sheetURL) {
      return [
        {
          type: 'link',
          label: 'Access the Google sheet at: ',
          value: sheetURL,
        },
      ];
    }

    return [];
  }, [sheetURL]);

  const shouldShowOutput = useMemo(() => {
    if (formik.isSubmitting) {
      return false;
    }

    return sheetURL || error;
  }, [sheetURL, error, formik]);

  if (isLoading) {
    return (
      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h1">
        Google Sheet Generation
      </Typography>
      <Typography variant="subtitle1" className={classes.subTitle}>
        Generate a new Google Sheet with the given app key and author
      </Typography>

      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <FormControl error={isError(formik, 'appKey')}>
          <MultiSelectDropdown
            value={formik.values.appKey}
            label="App Key"
            id="appKey"
            handleChange={formik.handleChange('appKey')}
            options={formattedAppKeys}
          />
          {isError(formik, 'appKey') && <FormHelperText>{formik.errors.appKey}</FormHelperText>}
        </FormControl>
        <FormControl error={isError(formik, 'author')}>
          <MultiSelectDropdown
            value={formik.values.author}
            label="Author"
            id="author"
            handleChange={formik.handleChange('author')}
            options={formattedAuthors}
          />
          {isError(formik, 'author') && <FormHelperText>{formik.errors.author}</FormHelperText>}
        </FormControl>
        <Button
          name="generate sheet"
          type="submit"
          variant="contained"
          color="primary"
          disabled={formik.isSubmitting}
          className={classes.submit}
        >
          {formik.isSubmitting ? <CircularProgress style={{color: 'inherit'}} size={24} /> : 'Generate Sheet'}
        </Button>
      </form>
      {shouldShowOutput ? (
        <Fragment>
          <Divider />
          <Output errors={error ? [error] : []} actions={actions} />
        </Fragment>
      ) : null}
    </div>
  );
}
