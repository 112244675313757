import 'App.css';

import React from 'react';
import {Routes, Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import {MuiThemeProvider} from '@material-ui/core/styles';

import PAGES from 'lib/pages';
import {defaultTheme} from 'lib/defaultTheme';
import Login from 'pages/Login';
import RequireAuth from 'components/Common/RequireAuth';
import PageLayout from 'pages/PageLayout';
import DocumentsViewer from 'components/DocumentsViewer/DocumentsViewer';
import GoogleSheetGeneration from 'components/GoogleSheetGeneration';
import DocumentManagement from 'components/DocumentManagement/DocumentManagement';
import DeleteUsers from 'components/DeleteUsers/DeleteUsers';
import ResourceTypeManagement from 'components/ResourceTypeManagement/ResourceTypeManagement';
import AppManagement from 'components/AppManagement/AppManagement';
import LabelSyncMainLayout from 'components/LabelSync/LabelSyncMainLayout';
import ContentSyncImportPreview from 'components/ContentSyncPreview/ContentSyncImportPreview';
import RegionManagement from 'components/RegionManagement/RegionManagement';
import LineOfBusinessManagement from 'components/LineOfBusinessManagement/LineOfBusinessManagement';
import AuthorManagement from 'components/AuthorManagement/AuthorManagement';

function renderAuthenticatedPage(page, docURL) {
  return (
    <RequireAuth>
      <PageLayout docURL={docURL}>{page}</PageLayout>
    </RequireAuth>
  );
}

function App() {
  return (
    <MuiThemeProvider theme={defaultTheme}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Login />} />
        <Route
          path={PAGES.GOOGLE_SHEET_GENERATION.route}
          element={renderAuthenticatedPage(<GoogleSheetGeneration />, PAGES.GOOGLE_SHEET_GENERATION.docURL)}
        />
        <Route
          path={PAGES.DOCUMENTS_VIEWER.route}
          element={renderAuthenticatedPage(<DocumentsViewer />, PAGES.DOCUMENTS_VIEWER.docURL)}
        />
        <Route
          path={PAGES.LABEL_SYNC.route}
          element={renderAuthenticatedPage(<LabelSyncMainLayout />, PAGES.LABEL_SYNC.docURL)}
        />
        <Route
          path={PAGES.DOCUMENT_MANAGEMENT.route}
          element={renderAuthenticatedPage(<DocumentManagement />, PAGES.DOCUMENT_MANAGEMENT.docURL)}
        />
        <Route
          path={PAGES.DELETE_USERS.route}
          element={renderAuthenticatedPage(<DeleteUsers />, PAGES.DELETE_USERS.docURL)}
        />
        <Route
          path={PAGES.REGION_MANAGEMENT.route}
          element={renderAuthenticatedPage(<RegionManagement />, PAGES.REGION_MANAGEMENT.docURL)}
        />
        <Route
          path={PAGES.CONTENT_SYNC_PREVIEW.route}
          element={renderAuthenticatedPage(<ContentSyncImportPreview />, PAGES.CONTENT_SYNC_PREVIEW.docURL)}
        />
        <Route
          path={PAGES.RESOURCE_TYPE_MANAGEMENT.route}
          element={renderAuthenticatedPage(<ResourceTypeManagement />, PAGES.RESOURCE_TYPE_MANAGEMENT.docURL)}
        />
        <Route
          path={PAGES.APP_MANAGEMENT.route}
          element={renderAuthenticatedPage(<AppManagement />, PAGES.APP_MANAGEMENT.docURL)}
        />
        <Route
          path={PAGES.LINE_OF_BUSINESS_MANAGEMENT.route}
          element={renderAuthenticatedPage(<LineOfBusinessManagement />, PAGES.LINE_OF_BUSINESS_MANAGEMENT.docURL)}
        />
        <Route
          path={PAGES.AUTHOR_MANAGEMENT.route}
          element={renderAuthenticatedPage(<AuthorManagement />, PAGES.AUTHOR_MANAGEMENT.docURL)}
        />
      </Routes>
    </MuiThemeProvider>
  );
}

App.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default App;
