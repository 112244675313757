import fetch from 'api/fetch';

class ContentSync {
  async previewContentSyncMetadata({appKeys = null, startDate = null, endDate = null, pageSize = 100, offset = 0}) {
    const data = {
      page_size: pageSize,
      offset,
    };

    if (appKeys !== null && appKeys.length > 0) {
      data.app_keys = appKeys;
    }

    if (startDate !== null) {
      data.start_date = startDate;
    }

    if (endDate !== null) {
      data.end_date = endDate;
    }

    try {
      const response = await fetch(process.env.REACT_APP_DIALOG_BASE_URL, '/content-sync/preview', 'POST', data);
      return response;
    } catch (error) {
      throw error.response.data;
    }
  }

  async retrieveDownloadURL(id) {
    try {
      const response = fetch(process.env.REACT_APP_DIALOG_BASE_URL, '/content-sync/download', 'POST', {
        id: id,
      });
      return response;
    } catch (error) {
      throw error.response.data;
    }
  }

  //SHOULDO: PBP-11654
  //Stub for download link
  async generateDownloadLink({idList}) {
    return null;
  }
}

export default new ContentSync();
