import fetch from 'api/fetch';
import {convertObjectKeysToCamelCase} from 'api/utils';
import {convertISODateStringToHuman} from 'lib/timeHelpers';
/**
 * The status of a Label Sync Job
 * @readonly
 * @enum {string}
 */
const DocumentManagementSheetStatus = {
  ACTIVE: 'ACTIVE',
  PREVIEWING: 'PREVIEWING',
  IMPORTING: 'IMPORTING',
  ARCHIVED: 'ARCHIVED',
  GENERATING: 'GENERATING',
};

/** Interface to backend DocumentManagement APIs */
const documentManagement = {
  generateSheet: async function(appKey, author, asynchronous) {
    const response = await fetch(process.env.REACT_APP_DIALOG_BASE_URL, '/document-management/generate-sheet', 'POST', {
      app_key: appKey || null,
      author: author || null,
      asynchronous: asynchronous,
    });

    return response.data;
  },

  previewChanges: async function(sheetKey) {
    try {
      const path = `/document-management/sheets/${sheetKey}/preview`;
      const response = await fetch(process.env.REACT_APP_DIALOG_BASE_URL, path, 'POST');
      return response.data;
    } catch (error) {
      throw new Error(error.response?.error?.message || error.message);
    }
  },

  importChanges: async function(sheetKey) {
    try {
      const path = `/document-management/sheets/${sheetKey}/import`;
      const response = await fetch(process.env.REACT_APP_DIALOG_BASE_URL, path, 'POST');
      return response.data;
    } catch (error) {
      throw new Error(error.response?.error?.message || error.message);
    }
  },

  /**
   * Transforms the response from the backend to a format that the front-end needs.
   * @param {Object} sheet
   */
  _transformSheet: function(sheet) {
    sheet = convertObjectKeysToCamelCase(sheet);

    sheet.updatedAt = sheet.updatedAt || sheet.createdAt;
    sheet.sheetURL = `https://docs.google.com/spreadsheets/d/${sheet.sheetKey}`;
    sheet.formattedUpdatedAt = sheet.updatedAt ? convertISODateStringToHuman(sheet.updatedAt + 'Z') : 'N/A';
    sheet.formattedCreatedAt = sheet.createdAt ? convertISODateStringToHuman(sheet.createdAt + 'Z') : 'N/A';
    sheet.formattedAuthorNames = sheet.authorNames.length ? sheet.authorNames.join(', ') : 'N/A';
    sheet.formattedAppKeys = sheet.appKeys.length ? sheet.appKeys.join(', ') : 'N/A';
    return sheet;
  },

  getAllSheets: async function() {
    const response = await fetch(process.env.REACT_APP_DIALOG_BASE_URL, '/document-management/sheets');
    const sheets = response.data.map((sheet) => {
      return this._transformSheet(sheet);
    });

    sheets.sort((a, b) => {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    });

    return sheets;
  },

  getSheet: async function(sheetKey) {
    const response = await fetch(process.env.REACT_APP_DIALOG_BASE_URL, '/document-management/sheets/' + sheetKey);
    return this._transformSheet(response.data);
  },
};

export {DocumentManagementSheetStatus};
export default documentManagement;
