import axios from 'axios';
import auth from 'api/auth';
import {HTTP_RESPONSE_STATUS_CODES} from 'lib/constants';
import {tokenRefresh} from 'lib/token_refresh';

const fetch = async function(baseURL, url, method = 'GET', requestBody = null) {
  const token = auth.getToken();
  const response = _rawFetch(token, baseURL, url, method, requestBody);
  return response;
};

const _rawFetch = async function(token, baseURL, url, method = 'GET', data = null) {
  const headers = {};

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const combinedOptions = {
    baseURL,
    url,
    method,
    headers,
    data,
    crossDomain: true,
    withCredentials: false,
  };

  try {
    const response = await _retryFetch(combinedOptions);
    return response;
  } catch (error) {
    // TODO: https://pronavtechnologies.atlassian.net/browse/PBP-8976
    // Put robust and consistent error handling here
    if (error.response && error.response.data && error.response.data.error) {
      switch (error.response.data.error) {
        case 'Signature has expired':
        case 'Invalid segment encoding':
          auth.clearStorage();
          break;
        default:
          throw error;
      }
    } else {
      throw error;
    }
  }
};

// supports non auth retries too, defaults to off
const _retryFetch = (combinedOptions, externalToken, authRetry = true, attemptsLeft = 0) => {
  return axios(combinedOptions)
      .catch(async (error) => {
        if (
          error.response?.status === HTTP_RESPONSE_STATUS_CODES.UNAUTHORIZED &&
        authRetry &&
        !externalToken &&
        'Authorization' in combinedOptions.headers
        ) {
          await tokenRefresh.refresh();

          combinedOptions.headers['Authorization'] = `Bearer ${auth.getToken()}`;

          return _retryFetch(combinedOptions, externalToken, false, attemptsLeft);
        }

        if (attemptsLeft <= 0) {
          throw error;
        }

        return _retryFetch(combinedOptions, externalToken, authRetry, attemptsLeft - 1);
      })
      .then((response) => {
        return response;
      });
};

export default fetch;
