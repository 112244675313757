import fetch from 'api/fetch';

const app = {
  /** Get app information */
  getSageApps: async function() {
    const response = await fetch(process.env.REACT_APP_DIALOG_BASE_URL, '/?app_type=sage');
    return response.data.apps;
  },
  postSageApp: async function(appId, desc, langs, appSubType) {
    const response = await fetch(process.env.REACT_APP_DIALOG_BASE_URL, appId, 'POST', {
      app: {
        app_type: 'sage',
        app_sub_type: appSubType,
        description: desc,
        customer_name: appId,
        languages: langs,
      },
      portal_config: {
        config: null,
        title: null,
        color: null,
        logo: null,
        tour_type: 'sage',
        downloadable_resource_types: [6],
        printable_resource_types: [6],
      },
      lines_of_business: ['home', 'auto'],
    });
    return response.data;
  },
  editSageApp: async function(appId, langs, subType, drt, prt) {
    const response1 = await fetch(process.env.REACT_APP_DIALOG_BASE_URL, appId, 'PUT', {
      app_sub_type: subType,
      languages: langs,
    });

    const response2 = await fetch(process.env.REACT_APP_DIALOG_BASE_URL, appId + '/portal-config', 'PUT', {
      downloadable_resource_types: drt,
      printable_resource_types: prt,
    });

    if (response1.status === 200) {
      return response2;
    } else return response1;
  },
};

export default app;
