import axios from 'axios';
import contentSync from 'api/contentSync';
import PropTypes from 'prop-types';
import React from 'react';

import {makeStyles} from '@material-ui/core';
import {downloadBlob} from 'lib/utils';

import {Link} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  downloadItem: {
    cursor: 'pointer',
  },
}));

const ContentSyncLink = ({id, additionalData}) => {
  const classes = useStyles();
  const handleClick = async () => {
    if (id) {
      try {
        const response = await contentSync.retrieveDownloadURL(id);
        const downloadURL = response.data.url;
        await axios.get(downloadURL, {responseType: 'blob'}).then((response) => {
          const filename = additionalData ? additionalData['bucket_key']?.split('/').pop() : Date.now();
          downloadBlob(filename, response.data, response.headers['content-type']);
        });
      } catch (error) {
        if (error.response) {
          if (error['response'].status === 404) {
            console.error('File not found.');
          } else if (error['response'].status === 403) {
            console.error('Forbidden to access file.');
          }
        } else {
          throw error;
        }
      }
    }
  };

  return (
    <Link data-testid="contentSyncLink" className={classes.downloadItem} onClick={handleClick}>
      Download File
    </Link>
  );
};

ContentSyncLink.propTypes = {
  id: PropTypes.number,
  additionalData: PropTypes.object,
};

export default ContentSyncLink;
