import React, {useEffect, useState, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';

import {makeStyles, useTheme} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import {EditSharp} from '@material-ui/icons';
import {Alert, AlertTitle} from '@material-ui/lab';
import {getErrorMessageString} from 'lib/helper';
import {getComparator, stableSort} from 'lib/sortingHelpers';

import resourceTypesApi from 'api/resourceTypes';
import ResourceTypeManagementModal from 'components/ResourceTypeManagement/ResourceTypeManagementModal';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    height: '100%',
    padding: '30px 50px',
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    width: '100%',
  },
  subTitle: {
    marginTop: 5,
    marginBottom: 10,
    marginLeft: 2,
    color: '#757575',
    fontStyle: 'italic',
  },
  table: {
    minWidth: 650,
  },
  tableRow: {
    '&:hover': {
      '& $editButton': {
        display: 'block',
      },
    },
    '&:active': {
      backgroundColor: 'rgb(111,176,199,0.5) !important',
    },
  },
  tableCell: {
    color: '#1f4958',
  },
  editCell: {
    padding: 0,
    paddingLeft: '1rem',
  },
  topBar: {
    display: 'flex',
    height: '100%',
  },
  searchBar: {
    margin: 15,
    width: '15%',
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#4096b4 !important',
    },
    '& :hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1f4958',
    },
    '& label.Mui-focused': {
      color: '#4096b4 !important',
    },
  },
  addButton: {
    margin: 15,
    marginLeft: 'auto',
    backgroundColor: '#4096b4',
    color: 'white',
    borderRadius: 5,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1f4958',
    },
  },
  editButton: {
    color: '#4096b4',
    display: 'none',
  },
}));

// Searching
// searches for all matches in all properties (id, title, createdAt, updatedAt) and returns an array of them
function findAll(arr, key) {
  key = key.toLowerCase();
  const matches = [];
  matches.push(
      ...arr.filter((obj) => {
        return Object.keys(obj).reduce((result, curKey) => {
          return result || obj[curKey].toString().toLowerCase().includes(key);
        }, false);
      }),
  );
  return matches;
}

function SearchBar(props) {
  const {data, setRows, setPage} = props;
  const classes = useStyles();

  function handleChange(event) {
    setRows(findAll(data, event.target.value));
    setPage(0);
  }

  return (
    <TextField
      label="Search Resource Types"
      variant="outlined"
      size="small"
      onChange={handleChange}
      className={classes.searchBar}
      inputProps={{style: {fontSize: 14, padding: 10}}}
      InputLabelProps={{style: {fontSize: 14}}}
    />
  );
}

SearchBar.propTypes = {
  data: PropTypes.array.isRequired,
  setRows: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
};
// End of Searching

// Add Resource Type
function AddResourceType(props) {
  const {refresh} = props;
  const [editorIsOpen, setEditorIsOpen] = useState(false);

  const classes = useStyles();

  function handleOpen() {
    setEditorIsOpen(true);
  }

  function handleClose() {
    setEditorIsOpen(false);
  }

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="contained"
        disableElevation
        className={classes.addButton}
        aria-label="add resource type"
        disabled
        >
        Add Resource Type
      </Button>
      <ResourceTypeManagementModal
        title="Add a Resource Type"
        isOpen={editorIsOpen}
        handleClose={handleClose}
        refresh={refresh}
      />
    </>
  );
}

AddResourceType.propTypes = {
  refresh: PropTypes.func.isRequired,
};
// End of Add Resource Type

// Table Head
const headers = [
  {id: 'id', label: 'ID'},
  {id: 'title', label: 'Title'},
  {id: 'createdAt', label: 'Created At'},
  {id: 'updatedAt', label: 'Updated At'},
];

function TableHeaders(props) {
  const {order, orderBy, onRequestSort} = props;

  return (
    <TableRow>
      {headers.map((headers) => (
        <TableCell key={headers.id} align="left" sortDirection={orderBy === headers.id ? order : false}>
          <TableSortLabel
            active={orderBy === headers.id}
            direction={orderBy === headers.id ? order : 'asc'}
            onClick={(event) => onRequestSort(event, headers.id)}
          >
            {headers.label}
          </TableSortLabel>
        </TableCell>
      ))}
      <TableCell align="left"></TableCell>
    </TableRow>
  );
}

TableHeaders.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};
// End of Table Head

// Table Body
function TableRows(props) {
  const {rows, order, orderBy, page, rowsPerPage, refresh} = props;
  const [editorIsOpen, setEditorIsOpen] = useState(false);
  const [clickedData, setClickedData] = useState(null);

  const classes = useStyles();

  const disableEdit = true;
  const handleClick = (event) => {
    if (disableEdit) {
      return;
    }

    setClickedData(rows.find((row) => parseInt(event.currentTarget.dataset.id) === row.id));
    setEditorIsOpen(true);
  };

  const handleClose = () => {
    setEditorIsOpen(false);
  };

  return (
    <>
      {
        /* getting the rows to be displayed on the current page */
        (rowsPerPage !== -1
          ? stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : stableSort(rows, getComparator(order, orderBy))
        ).map((row) => {
          return (
            <TableRow
              hover
              onClick={handleClick}
              className={classes.tableRow}
              key={row.id}
              data-id={row.id}
              data-testid={`table-row:${row.id}`}
              >
              <TableCell>{row.id}</TableCell>
              <TableCell align="left" className={classes.tableCell}>
                {row.title}
              </TableCell>
              <TableCell align="left">{row.createdAt}</TableCell>
              <TableCell align="left">{row.updatedAt}</TableCell>
              <TableCell align="right" className={classes.editCell}>
                <EditSharp className={classes.editButton} />
              </TableCell>
            </TableRow>
          );
        })
      }
      {clickedData && (
        <ResourceTypeManagementModal
          title="Edit a Resource Type"
          isOpen={editorIsOpen}
          handleClose={handleClose}
          resourceType={clickedData}
          refresh={refresh}
        />
      )}
    </>
  );
}

TableRows.propTypes = {
  rows: PropTypes.array.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  refresh: PropTypes.func.isRequired,
};
// End of Table Body

// Pagination
function TablePaginationActions(props) {
  const theme = useTheme();
  const {count, page, rowsPerPage, onPageChange} = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
// End of Pagination

export default function ResourceTypeManagement() {
  const classes = useStyles();

  // data that remains the same when searching
  const data = useRef([]);
  // rows in table that change when searching
  const [rows, setRows] = useState([]);
  // sorting states
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  // pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // loading
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(null);

  // helper function to change property names of api data
  function createData(id, title, createdAt, updatedAt) {
    let formattedCreatedAt = new Date(createdAt).toLocaleString('en-ca');
    let formattedUpdatedAt = new Date(updatedAt).toLocaleString('en-ca');
    formattedCreatedAt =
      formattedCreatedAt.substring(0, formattedCreatedAt.indexOf(':', formattedCreatedAt.indexOf(':') + 1)) + ' pm';
    formattedUpdatedAt =
      formattedUpdatedAt.substring(0, formattedUpdatedAt.indexOf(':', formattedUpdatedAt.indexOf(':') + 1)) + ' pm';
    return {
      id,
      title,
      createdAt: formattedCreatedAt,
      updatedAt: formattedUpdatedAt,
    };
  }

  // get data from api and put it into rows
  const refresh = useCallback(() => {
    let abort = false;

    (async () => {
      setIsLoading(true);

      try {
        let resourceTypes = await resourceTypesApi.getResourceTypes();
        if (abort) {
          return;
        }

        if (!resourceTypes || resourceTypes.length === 0) {
          throw new Error('No resource types found');
        }

        resourceTypes = resourceTypes.map((resourceType) => {
          return createData(resourceType.id, resourceType.title, resourceType.created_at, resourceType.updated_at);
        });

        data.current = resourceTypes;
        setRows(resourceTypes);
      } catch (err) {
        setLoadingError(getErrorMessageString(err));
      }

      setIsLoading(false);
    })();

    return () => {
      abort = true;
    };
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  // pagination functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // end of pagination functions

  // sorting functions
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  };
  // end of sorting functions

  // displays loading screen is data is being loaded
  if (isLoading) {
    return (
      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h1">
        Resource Type Management
      </Typography>
      <Typography variant="subtitle1" className={classes.subTitle}>
        Displaying a List of Resource Types
      </Typography>

      <Paper className={classes.paper}>
        <TableContainer>
          <Divider />
          <Divider />
          <div className={classes.topBar}>
            <SearchBar data={data.current} setRows={setRows} setPage={setPage} />
            <AddResourceType refresh={refresh} />
          </div>
          <Divider />
          <Table className={classes.table}>
            <colgroup>
              <col style={{width: '5%'}} />
              <col style={{width: '30%'}} />
              <col style={{width: '30%'}} />
              <col style={{width: '30%'}} />
              <col style={{width: '5%'}} />
            </colgroup>
            <TableHead>
              <TableHeaders order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            </TableHead>
            <TableBody>
              <TableRows
                rows={rows}
                order={order}
                orderBy={orderBy}
                page={page}
                rowsPerPage={rowsPerPage}
                refresh={refresh}
              />
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, {label: 'All', value: -1}]}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>

      {/* displays error if there was an error getting data from api */}
      {loadingError && (
        <Alert severity="error" className={classes.loadingError} data-testid={`error:${loadingError}`}>
          <AlertTitle>Error Loading Apps</AlertTitle>
          {loadingError}
        </Alert>
      )}
    </div>
  );
}
