import React from 'react';
import PropTypes from 'prop-types';
import {Alert} from '@material-ui/lab';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '30px 50px',
  },
}));

export default function ProductionAlert({isProduction}) {
  const classes = useStyles();

  if (!isProduction) {
    return null;
  }

  return (
    <Alert variant="filled" severity="info" className={classes.root}>
      This internal tools site is currently pointing to production.
    </Alert>
  );
}

ProductionAlert.propTypes = {
  isProduction: PropTypes.bool.isRequired,
};
