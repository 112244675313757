import React, {Fragment, useEffect, useState, useMemo, useCallback} from 'react';
import {useFormik} from 'formik';

import {makeStyles} from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {Alert, AlertTitle} from '@material-ui/lab';

import app from 'api/app';
import user from 'api/user';
import UserList from 'components/DeleteUsers/UserList';
import Dropdown from 'components/Dropdown';
import {isError, getErrorMessageString} from 'lib/helper';
import fetchAll from 'lib/fetchAll';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    height: '100%',
    padding: '30px 50px',
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  submit: {
    padding: '13px 0px',
    textTransform: 'inherit',
    width: '100%',
    marginTop: '3%',
  },
  subTitle: {
    color: '#757575',
    fontStyle: 'italic',
  },
  selectedAppLabel: {
    fontWeight: 600,
  },
}));

export default function DeleteUsers() {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [appLoadingError, setAppLoadingError] = useState(null);
  const [appKeys, setAppKeys] = useState([]);
  const [selectedAppKey, setSelectedAppKey] = useState('');

  const [isLoadingMemberships, setIsLoadingMemberships] = useState(false);
  const [membershipLoadingError, setMembershipLoadingError] = useState(null);
  const [appMemberships, setAppMemberships] = useState([]);

  const USERS_FETCH_PAGE_LIMIT = 1000;

  // Load all available app keys
  useEffect(() => {
    let abort = false;

    (async () => {
      setIsLoading(true);
      setAppKeys([]);

      try {
        const appKeys = await app.getSageApps();
        if (abort) {
          return;
        }

        if (!appKeys || appKeys.length === 0) {
          throw new Error('No app keys found');
        }

        setAppKeys(appKeys);
      } catch (err) {
        setAppLoadingError(getErrorMessageString(err));
      }

      setIsLoading(false);
    })();
    return () => {
      abort = true;
    };
  }, []);

  const getAllUsers = () => {
    if (!selectedAppKey) {
      return;
    }
    let abort = false;
    setIsLoadingMemberships(true);

    fetchAll((offset, pageSize) => {
      return user.getPagedUserMembershipsByAppKey(selectedAppKey, offset, pageSize);
    }, USERS_FETCH_PAGE_LIMIT)
        .then((allObjs) => {
          if (abort) {
            return;
          }

          const userMemberships = allObjs.map((membership) => ({
            id: membership.primary_user_id,
            firstName: membership.first_name,
            lastName: membership.last_name,
            email: membership.email ? membership.email : 'None',
          }));

          if (userMemberships.length === 0) {
            setMembershipLoadingError('No Memberships found for app');
          }
          setAppMemberships(userMemberships);
        })
        .catch((error) => {
          setMembershipLoadingError(getErrorMessageString(error));
        })
        .finally(() => {
          setIsLoadingMemberships(false);
        });
    return () => {
      abort = true;
      setIsLoadingMemberships(false);
      setAppMemberships([]);
    };
  };

  useEffect(getAllUsers, [selectedAppKey]);

  const formattedAppKeys = useMemo(() => {
    return appKeys
        .map((app) => ({
          key: app.app_id,
          value: app.app_id,
          label: app.description,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
  }, [appKeys]);

  const selectedAppLabel = useMemo(() => {
    if (!selectedAppKey || !formattedAppKeys || !formattedAppKeys.length) {
      return null;
    }

    const appData = formattedAppKeys.find(({key}) => key === selectedAppKey);
    if (!appData) {
      return null;
    }

    return appData.label || appData.key;
  }, [selectedAppKey, formattedAppKeys]);

  const formik = useFormik({
    initialValues: {appKey: ''},
    validate: useCallback(({appKey}) => {
      const errors = {};
      if (!appKey) {
        errors.appKey = 'Select an App';
      }
      return errors;
    }, []),
    onSubmit: useCallback(
        async (values) => {
          const currAppName = values.appKey;
          setSelectedAppKey(currAppName);
        },
        [setSelectedAppKey],
    ),
  });

  const handleReset = () => {
    formik.resetForm();
    setSelectedAppKey('');
  };

  if (isLoading || isLoadingMemberships) {
    return (
      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h1">
        Delete User Profiles
      </Typography>
      <Typography variant="subtitle1" className={classes.subTitle}>
        Delete users from different apps
      </Typography>

      {selectedAppKey && !membershipLoadingError ? (
        <Fragment>
          <Divider />
          <UserList
            tableTitle={
              <Grid item container direction="row" spacing={1} alignItems="center">
                <Grid item className={classes.selectedAppLabel}>
                  {selectedAppLabel}
                </Grid>
                <Grid item className={classes.selectedAppKey}>
                  ({selectedAppKey})
                </Grid>
              </Grid>
            }
            initialUsers={appMemberships}
            handleReset={handleReset}
            selectedAppKey={selectedAppKey}
            onDelete={getAllUsers}
          />
        </Fragment>
      ) : (
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <FormControl>
            <Dropdown
              value={formik.values.appKey}
              label="App Key"
              id="appKey"
              handleChange={formik.handleChange('appKey')}
              helperText={formik.errors.appKey}
              isError={isError(formik, 'appKey')}
              options={formattedAppKeys}
            />
          </FormControl>
          <Button
            name="select app"
            type="submit"
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting}
            className={classes.submit}
          >
            {formik.isSubmitting ? <CircularProgress style={{color: 'inherit'}} size={24} /> : 'Select App'}
          </Button>

          {appLoadingError && (
            <Alert severity="error" data-testid={`error:${appLoadingError}`}>
              <AlertTitle>Error Loading Apps</AlertTitle>
              {appLoadingError}
            </Alert>
          )}
        </form>
      )}
    </div>
  );
}
