import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {Typography, Button} from '@material-ui/core';
import ListItem from 'components/Common/ListItem';
import sheetModel from 'components/DocumentManagement/SheetModel';
import {DocumentManagementSheetStatus} from 'api/documentManagement';

function DocumentManagementListItem({
  style,
  classes,
  index,
  sheet,
  onRowClick,
  onPreviewClick,
  onApplyChangesClick,
  shouldEnableActions,
}) {
  const [isClicked, setIsClicked] = useState(false);

  // ListItem component uses the id as the data-testid
  sheet.id = sheet.recordId;

  const onClickHandler = (e) => {
    // If the user clicked on a link, don't trigger on RowClick
    if (e.target.tagName.toLowerCase() === 'a') {
      return;
    }

    onRowClick(sheet);
  };

  return (
    <ListItem
      style={style}
      className={classes.listItem}
      index={index}
      onClick={onClickHandler}
      item={sheet}
      columns={[
        {
          id: 1,
          render: () => sheet.recordId,
          className: classes.idColumn,
        },
        {
          id: 2,
          render: (sheet) => (
            <Typography variant="body2" noWrap>
              {sheet.status === DocumentManagementSheetStatus.GENERATING ? (
                'generating...'
              ) : (
                <a href={sheet.sheetURL} target="_blank" rel="noreferrer">
                  {sheet.sheetKey}
                </a>
              )}
            </Typography>
          ),
          className: classes.sheetColumn,
        },
        {
          id: 3,
          render: (sheet) => (
            <Typography variant="body2" noWrap>
              {sheet.status}
            </Typography>
          ),
          className: classes.statusColumn,
        },
        {
          id: 4,
          render: (sheet) => (
            <Typography variant="body2" noWrap>
              {sheet.formattedAppKeys}
            </Typography>
          ),
          className: classes.appKeysColumn,
        },
        {
          id: 5,
          render: (sheet) => (
            <Typography variant="body2" noWrap>
              {sheet.formattedAuthorNames}
            </Typography>
          ),
          className: classes.authorsColumn,
        },
        {
          id: 6,
          render: (sheet) => (
            <Typography variant="body2" noWrap>
              {sheet.createdBy}
            </Typography>
          ),
          className: classes.createdByColumn,
        },
        {
          id: 8,
          render: (sheet) => (
            <Typography variant="body2" noWrap>
              {sheet.formattedUpdatedAt}
            </Typography>
          ),
          className: classes.updatedAtColumn,
        },
        {
          id: 9,
          render: (sheet) => (
            <>
              <Button
                name="preview"
                variant="contained"
                color="primary"
                disabled={isClicked || !shouldEnableActions(sheet)}
                className={classes.button}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsClicked(true);
                  onPreviewClick(sheet);
                }}
              >
                Preview
              </Button>
              <Button
                name="apply"
                type="button"
                variant="contained"
                color="primary"
                disabled={isClicked || !shouldEnableActions(sheet)}
                className={clsx(classes.button, classes.applyChanges)}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsClicked(true);
                  onApplyChangesClick(sheet);
                }}
              >
                Apply
              </Button>
            </>
          ),
        },
      ]}
    />
  );
}

DocumentManagementListItem.propTypes = {
  style: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  sheet: PropTypes.shape(sheetModel).isRequired,
  onRowClick: PropTypes.func.isRequired,
  onPreviewClick: PropTypes.func.isRequired,
  onApplyChangesClick: PropTypes.func.isRequired,
  shouldEnableActions: PropTypes.func.isRequired,
};

export default DocumentManagementListItem;
