import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, TableSortLabel, TextField, Typography, Button, TablePagination} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import {useEffect, useState} from 'react';
import resources from 'api/resources';
import AuthorManagementModal from 'components/AuthorManagement/AuthorManagementModal';
import AuthorManagementEditModal from 'components/AuthorManagement/AuthorManagementEditModal';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    height: '100%',
    padding: '30px 50px',
    display: 'flex',
    flexDirection: 'column',
  },
  subTitle: {
    color: '#757575',
    fontStyle: 'italic',
    marginTop: 5,
    marginBottom: 20,
  },
  button: {
    height: '40px',
    marginLeft: '10px',
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.hoverMain,
    },
    borderRadius: '4px',
    textTransform: 'none',
  },
  table: {
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: theme.palette.secondary.light,
  },
  input: {
    margin: '0px',
    marginLeft: '0px',
    marginBottom: '20px',
    display: 'flex',
  },
  header: {
    fontWeight: 'bold',
  },
  addAuthorContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'right',
  },
  searchBar: {
    minWidth: '222px',
    maxWidth: '222px',
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#4096b4 !important',
    },
    '& :hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1f4958',
    },
    '& label.Mui-focused': {
      color: '#4096b4 !important',
    },
  },
  pagination: {
    overflow: 'hidden',
  },
  authorImage: {
    height: '60px',
    maxWidth: '100%', // Ensure the image doesn't exceed the grid item's width
    maxHeight: '100%', // Ensure the image doesn't exceed the grid item's height
    padding: '3px 16px',
  },
}));

export default function AuthorManagement() {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lastId, setLastId] = useState(0);
  const [newRows, setNewRows] = useState([]);

  const classes = useStyles();

  function createData(id, nameEN, image) {
    return {id, nameEN, image};
  }

  useEffect(() => {
    const fetchAuthors = async () => {
      setRows([]);

      try {
        const authors = await resources.getAuthors();

        if (!authors || authors.length === 0) {
          throw new Error('No authors found');
        }

        setRows(
            authors.map((author) => {
              setLastId(author.id);
              return createData(author.id, author.name, author.image);
            }),
        );
        setNewRows(
            authors.map((author) => {
              return createData(author.id, author.name, author.image);
            }),
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchAuthors();
  }, []);

  const handleSearch = (event) => {
    setSearch(event.target.value);
    if (event.target.value === '') {
      setRows(newRows);
    }
  };

  const filterSearch = () => {
    setRows(
        rows.filter((row) => row.nameEN.toLowerCase().includes(search.toLowerCase()) || row.id.toString() === search),
        setPage(0),
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const currentRows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const sortedRows = rows.sort((a, b) => {
      if (order === 'asc') {
        return a[property] > b[property] ? 1 : -1;
      } else {
        return b[property] > a[property] ? 1 : -1;
      }
    });
    setRows(sortedRows);
  };

  const addNewAuthor = (newAuthor) => {
    setLastId(lastId + 1);
    setNewRows([...newRows, createData(lastId + 1, newAuthor.name, newAuthor.image)]);
    setRows([...rows, createData(lastId + 1, newAuthor.name, newAuthor.image)]);
  };

  const addEditAuthor = (authorId, updatedAuthor) => {
    const newRows = rows.map((row) => {
      if (row.id === authorId) {
        return createData(row.id, updatedAuthor.name, row.image);
      }
      return row;
    });
    setRows(newRows);
    setNewRows(newRows);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h1">
        Author Management
      </Typography>
      <Typography variant="subtitle1" className={classes.subTitle}>
        Manage your Author
      </Typography>
      <div className={classes.input}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              filterSearch();
            }
          }}
          className={classes.searchBar}
        />
        <Button
          variant="contained"
          onClick={filterSearch}
          disableElevation
          className={classes.button}
          aria-label="Search"
        >
          <SearchIcon />
        </Button>
        <div className={classes.addAuthorContainer}>
          <AuthorManagementModal addNewAuthor={addNewAuthor} />
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <colgroup>
            <col style={{width: '10%'}} />
            <col style={{width: '15%'}} />
            <col style={{width: '70%'}} />
          </colgroup>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell align="left" className={classes.header}>
                <TableSortLabel
                  active={orderBy === 'id'}
                  direction={order === 'asc' ? 'asc' : 'desc'}
                  onClick={() => handleSort('id')}
                >
                  ID
                </TableSortLabel>
              </TableCell>
              <TableCell align="left" className={classes.header}>
                <TableSortLabel
                  active={orderBy === 'nameEN'}
                  direction={order === 'asc' ? 'asc' : 'desc'}
                  onClick={() => handleSort('nameEN')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell></TableCell>
              <TableCell align="left" className={classes.header}>
                Image
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentRows.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="left">{row.nameEN}</TableCell>
                <TableCell align="right">
                  <Box component="img" className={classes.authorImage} alt={row.nameEN} src={row.image} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <AuthorManagementEditModal name={row.nameEN} authorId={row.id} addEditAuthor={addEditAuthor} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={classes.pagination}
      />
    </div>
  );
}
