import React, {useEffect, useState, Fragment} from 'react';
import PropTypes from 'prop-types';

import {Alert} from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import {Dialog, DialogTitle, DialogContent, Divider, makeStyles, IconButton} from '@material-ui/core';

import {SHEET_DIFF_POLLING_INTERVAL, COMPONENT_STATUS} from 'lib/constants';
import documentManagement, {DocumentManagementSheetStatus} from 'api/documentManagement';

import sheetModel from 'components/DocumentManagement/SheetModel';
import SheetSummary from 'components/DocumentManagement/SheetSummary';
import SheetDiff from 'components/DocumentManagement/SheetDiff';

const useStyles = makeStyles(() => ({
  content: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  closeButton: {
    marginLeft: 'auto',
  },
  title: {
    '& .MuiTypography-root': {
      display: 'flex',
    },
  },
  sheetDiff: {
    marginTop: '16px',
  },
}));

function SheetDialog({sheet, onClose}) {
  const classes = useStyles();
  const dialogOpen = sheet ? true : false;
  const [sheetDetails, setSheetDetails] = useState(null);
  const [fetchState, setFetchState] = useState(COMPONENT_STATUS.LOADING);
  const [fetchError, setFetchError] = useState(null);
  const [nextLoadTime, setNextLoadTime] = useState(null);

  useEffect(() => {
    async function fetchSheetDetails() {
      console.info('Fetching sheet details...');
      try {
        const latestSheetDetails = await documentManagement.getSheet(sheet.sheetKey);
        setSheetDetails(latestSheetDetails);
      } catch (error) {
        setFetchError(error);
      }
    }

    // if we have requested to load, fetch sheet details and update state
    if (fetchState === COMPONENT_STATUS.LOADING) {
      fetchSheetDetails();
    }
  }, [sheet, fetchState, setSheetDetails, setFetchError]);

  useEffect(() => {
    // if we don't have sheet details, skip
    if (!sheetDetails) {
      return;
    }

    console.info(`Loaded sheet data. Sheet is in ${sheetDetails.status} state`);
    setFetchState(COMPONENT_STATUS.SUCCESS);
    const processingStates = [DocumentManagementSheetStatus.PREVIEWING, DocumentManagementSheetStatus.IMPORTING];
    const isSheetProcessing = processingStates.includes(sheetDetails.status);
    if (isSheetProcessing) {
      // if sheet is still processing, schedule a load
      const now = new Date();
      const loadTime = new Date(now.setSeconds(now.getSeconds() + SHEET_DIFF_POLLING_INTERVAL / 1000));
      console.info(`Scheduling reload for ${loadTime.toLocaleTimeString()}`);
      setNextLoadTime(loadTime);
      setTimeout(() => {
        setNextLoadTime(null);
        setFetchState(COMPONENT_STATUS.LOADING);
      }, SHEET_DIFF_POLLING_INTERVAL);
    }
  }, [sheetDetails, setFetchState]);

  useEffect(() => {
    if (fetchError) {
      console.error(`Failed to fetch sheet details: ${fetchError}`);
      setFetchState(COMPONENT_STATUS.ERROR);
    }
  }, [fetchError, setFetchState]);

  return (
    <Dialog open={dialogOpen} scroll="paper" onClose={onClose} fullWidth={true} maxWidth="lg">
      <DialogTitle className={classes.title}>
        <span>Sheet # {sheet.recordId}</span>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        {nextLoadTime && (
          <Alert severity="info">
            Automatically refreshing at {nextLoadTime.toLocaleTimeString(undefined, {hour12: true})}
          </Alert>
        )}
        {fetchState === COMPONENT_STATUS.LOADING && <Alert severity="info">Fetching latest sheet information...</Alert>}
        {fetchState === COMPONENT_STATUS.ERROR && (
          <Alert severity="error">Error loading sheet details: {fetchError.message}</Alert>
        )}
        {sheetDetails && (
          <Fragment>
            <br />
            <SheetSummary sheet={sheetDetails} />
            <br />
            <SheetDiff diff={sheetDetails.diff} className={classes.sheetDiff} />
          </Fragment>
        )}
      </DialogContent>
    </Dialog>
  );
}

SheetDialog.propTypes = {
  sheet: PropTypes.shape(sheetModel).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SheetDialog;
