export function isError(formik, field) {
  return !!formik.errors[field] && !!formik.touched[field];
}

/**
 * Attempts to retrieve an error message string from the provided parameter
 * @param {*} err The error object to attempt to retrieve a message value from
 * @returns The retrieved error message, or 'Unknown Error' if no message value
 *          could be retrieved.
 */
export function getErrorMessageString(err) {
  if (err) {
    let messageValue = null;
    if (err instanceof Error) {
      messageValue = err.message;
    } else if (Object.prototype.hasOwnProperty.call(err, 'message') && typeof err.message === 'string') {
      messageValue = err.message;
    } else if (typeof err === 'string') {
      messageValue = err;
    } else if (Object.prototype.hasOwnProperty.call(err, 'toString') && typeof err.toString === 'function') {
      messageValue = err.toString();
    }

    if (messageValue) {
      return messageValue;
    }
  }

  console.error('Unable to retrieve error message string for error:', err);
  return 'Unknown Error';
}

/**
 * Recursively freezes the passed object and its descendant values to prevent mutation.
 * @param {(Object|Array)} obj The root object to be frozen
 * @returns {(Object|Array)} The passed in object, but now deeply frozen
 */
export function deepFreeze(obj) {
  const propNames = Object.getOwnPropertyNames(obj);
  for (const name of propNames) {
    const value = obj[name];
    if (value && typeof value === 'object') {
      deepFreeze(value);
    }
  }

  if (typeof obj === 'object') {
    Object.freeze(obj);
  }

  return obj;
}
