import React, {useState} from 'react';

import {makeStyles, Typography} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabItem from 'components/Common/TabItem';

import StartSyncJobTab from 'components/LabelSync/StartSyncJobTab';
import SyncJobHistoryTab from 'components/LabelSync/SyncJobHistoryTab';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    height: '100%',
    padding: '30px 50px',
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: 25,
  },
  button: {
    padding: '13px 0px',
    textTransform: 'inherit',
    fontSize: 15,
    marginTop: 18,
    marginRight: 15,
    width: 250,
  },
  subTitle: {
    color: '#757575',
    fontStyle: 'italic',
  },
  tab: {
    height: '100%',
  },
}));

function a11yProps(index) {
  return {
    id: `label-sync-tab-${index}`,
    'aria-controls': `label-sync-tabpanel-${index}`,
  };
}

export default function LabelSyncMainLayout() {
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState(0);

  const changeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h1">
        Label Sync
      </Typography>
      <Typography variant="subtitle1" className={classes.subTitle}>
        Update labels for documents and view historical changes
      </Typography>

      <Tabs value={selectedTab} onChange={changeTab} aria-label="label sync tabs">
        <Tab label="Start Job" {...a11yProps(0)} />
        <Tab label="History" {...a11yProps(1)} />
      </Tabs>
      <TabItem index={0} selectedTab={selectedTab} className={classes.tab}>
        <StartSyncJobTab />
      </TabItem>
      <TabItem index={1} selectedTab={selectedTab} className={classes.tab} keepMounted={false}>
        <SyncJobHistoryTab />
      </TabItem>
    </div>
  );
}
