import {Grid, Typography} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  spacing: {
    paddingTop: '20vh',
  },
  progress: {
    paddingRight: '10px',
  },
}));

function WaitingScreen(props) {
  const {text, className} = props;
  const classes = useStyles();

  return (
    <Grid
      container
      className={clsx(className, classes.spacing)}
      direction="row"
      justify="flex-start"
      alignItems="center"
      >
      <Grid item className={classes.progress}>
        <CircularProgress />
      </Grid>
      <Grid item>
        <Typography>{text}</Typography>
      </Grid>
    </Grid>
  );
}

WaitingScreen.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

export default WaitingScreen;
