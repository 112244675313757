import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import {makeStyles, alpha} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import ContentSyncListItem from 'components/ContentSyncPreview/ContentSyncListItem';
import VirtualizedList from 'components/Common/VirtualizedList';

const ROW_HEIGHT = 46;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '10px 0px',
  },
  listContainer: {
    flexGrow: 1,
  },
  tableHeader: {
    paddingBottom: '5px',
    height: '70px',
    flexWrap: 'nowrap',
  },
  tableRow: {
    '&:hover': {
      background: alpha(theme.palette.primary.main, 0.2),
    },
  },
  tableRowOdd: {
    background: alpha(theme.palette.primary.main, 0.05),
  },
  header: {
    paddingLeft: '5px',
  },
  headerOdd: {
    background: alpha(theme.palette.primary.main, 0.3),
  },
  headerEven: {
    background: alpha(theme.palette.primary.main, 0.5),
  },
  documentCell: {
    paddingLeft: '5px',
  },
  xlTooltip: {
    maxWidth: '500px',
  },
  longColumn: {
    width: '25%',
    flexGrow: 3,
    height: '100%',
  },
  mediumColumn: {
    width: '13%',
    flexGrow: 2,
    height: '100%',
  },
  smallColumn: {
    width: '5%',
    flexGrow: 1,
    height: '100%',
  },
}));

export default function ContentSyncPreviewPagedList({data, tableTitle, config}) {
  const classes = useStyles();

  const HeaderCell = ({title, classKey, index}) => {
    return (
      <Grid
        item
        container
        alignItems="center"
        className={clsx(classes[classKey], index % 2 === 1 ? classes.headerOdd : classes.headerEven, classes.header)}
        >
        <Typography variant="body2">{title}</Typography>
      </Grid>
    );
  };
  HeaderCell.propTypes = {
    title: PropTypes.string,
    classKey: PropTypes.string,
    index: PropTypes.number,
  };

  return (
    <Grid container direction="column" className={classes.root} spacing={2}>
      <Grid item container direction="row" alignItems="center" spacing={1}>
        <Grid item data-testid="content-sync-preview-list-table-title" style={{flexGrow: '1'}}>
          {tableTitle}
        </Grid>
      </Grid>
      <Grid item className={classes.listContainer}>
        <VirtualizedList
          items={data}
          rowHeight={ROW_HEIGHT}
          header={
            <Grid container direction="row" alignItems="center" className={classes.tableHeader} data-testid="">
              {config.map((config, configIndex) => (
                <HeaderCell {...config} index={configIndex} key={`headerColumn-${configIndex}`} />
              ))}
            </Grid>
          }
          rowTemplate={({index, item, style, key}) => (
            <ContentSyncListItem
              key={key}
              style={style}
              classes={classes}
              index={index}
              document={item}
              dataConfig={config}
              additionalData={item}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

ContentSyncPreviewPagedList.propTypes = {
  data: PropTypes.array,
  tableTitle: PropTypes.string,
  config: PropTypes.array,
};
