import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import Output from 'components/Common/Output';

function SyncHistoryJobDisplayDialog({syncJob, handleClose}) {
  const dialogOpen = syncJob ? true : false;

  return (
    <Dialog open={dialogOpen} scroll="paper" onClose={handleClose} width="fullWidth" maxWidth="lg">
      {syncJob ? (
        <DialogContent>
          <DialogContentText>
            <Typography>
              Sync #{syncJob.id} - {syncJob.status}
            </Typography>
          </DialogContentText>
          <Output errors={syncJob.diff.errors} actions={syncJob.diff.actions} warnings={syncJob.diff.warnings} />
        </DialogContent>
      ) : null}
    </Dialog>
  );
}

const diffShape = {
  actions: PropTypes.arrayOf(PropTypes.string).isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  warnings: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const syncJobShape = {
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  sheetName: PropTypes.string.isRequired,
  diff: PropTypes.shape(diffShape),
  startedAt: PropTypes.string.isRequired,
  completedAt: PropTypes.string,
};

SyncHistoryJobDisplayDialog.propTypes = {
  syncJob: PropTypes.shape(syncJobShape),
  handleClose: PropTypes.func,
};

export default SyncHistoryJobDisplayDialog;
