import PropTypes from 'prop-types';

const sheetModel = {
  appKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  authorNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  createdBy: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  formattedCreatedAt: PropTypes.string.isRequired,
  formattedUpdatedAt: PropTypes.string.isRequired,
  formattedAuthorNames: PropTypes.string.isRequired,
  formattedAppKeys: PropTypes.string.isRequired,
  recordId: PropTypes.number.isRequired,
  sheetKey: PropTypes.string.isRequired,
  sheetURL: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
};

export default sheetModel;
