import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import CheckBox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

const UserListItem = React.memo(function UserListItem({
  style,
  classes,
  index,
  account,
  setDeletionState,
  isSelected,
}) {
  const isOddRow = index % 2 === 1;

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      spacing={1}
      className={clsx(classes.tableRow, isOddRow && classes.tableRowOdd)}
      style={style}
      >
      <Grid item container className={classes.smallGridColumn}>
        <CheckBox checked={isSelected} onChange={(e) => setDeletionState(e, account.id)} />
      </Grid>
      <Grid item className={classes.idColumn}>
        {account.id}
      </Grid>
      <Grid item className={classes.mainGridColumn}>
        <Typography variant="body2" noWrap>
          {`${account.firstName} ${account.lastName}`}
        </Typography>
      </Grid>
      <Grid item className={classes.mainGridColumn}>
        <Typography variant="body2" noWrap>
          {account.email}
        </Typography>
      </Grid>
    </Grid>
  );
});

UserListItem.propTypes = {
  style: PropTypes.object,
  classes: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  account: PropTypes.object.isRequired,
  setDeletionState: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default UserListItem;
