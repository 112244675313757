import React, {useState, useMemo, useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import debounce from 'lodash.debounce';
import escapeRegExp from 'lodash.escaperegexp';

import {makeStyles, alpha} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {IconButton, Chip} from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import VirtualizedList from 'components/Common/VirtualizedList';
import {formatISODateString} from 'lib/timeHelpers';
import {TEXT_INPUT_ONCHANGE_DEBOUNCE_DELAY} from 'lib/constants';
import DocumentsViewerListItem from 'components/DocumentsViewer/DocumentsViewerListItem';
import {downloadBlob, toCSV} from 'lib/utils';

const ROW_HEIGHT = 46;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '10px 0px',
  },
  filterInput: {
    width: '400px',
  },
  legendResourceIdentity: {
    background: alpha(theme.palette.primary.resourceIdentity, 0.5),
  },
  legendResource: {
    background: alpha(theme.palette.primary.resource, 0.5),
  },
  legendText: {
    color: '#757575',
    fontStyle: 'italic',
    fontSize: '0.8em',
  },
  listContainer: {
    flexGrow: 1,
  },
  tableHeader: {
    paddingBottom: '5px',
  },
  tableRow: {
    '&:hover': {
      background: alpha(theme.palette.primary.main, 0.2),
    },
  },
  tableRowOdd: {
    background: alpha(theme.palette.primary.main, 0.05),
  },
  identityHeader: {
    background: alpha(theme.palette.primary.resourceIdentity, 0.5),
    paddingLeft: '5px',
  },
  identityCell: {
    background: alpha(theme.palette.primary.resourceIdentity, 0.1),
    paddingLeft: '5px',
  },
  resourceHeader: {
    background: alpha(theme.palette.primary.resource, 0.5),
    paddingLeft: '5px',
  },
  resourceCell: {
    background: alpha(theme.palette.primary.resource, 0.1),
    paddingLeft: '5px',
  },
  documentHeader: {
    paddingLeft: '5px',
  },
  documentCell: {
    paddingLeft: '5px',
  },
  xlTooltip: {
    maxWidth: '500px',
  },
  longColumn: {
    width: '30%',
    height: '100%',
  },
  mediumColumn: {
    width: '15%',
    height: '100%',
  },
  smallColumn: {
    width: '8%',
    height: '100%',
  },
}));

export default function DocumentsViewerList({
  tableTitle,
  initialDocuments,
  appKey,
  debounceDelay = TEXT_INPUT_ONCHANGE_DEBOUNCE_DELAY, // exposed for testing purposes
}) {
  const classes = useStyles();

  const [documents, setDocuments] = useState([]);
  const [filterValue, setFilterValue] = useState('');

  useEffect(() => {
    const documents = initialDocuments.slice();
    documents.sort((a, b) => {
      return (a.master_name || '').localeCompare(b.master_name);
    });
    setDocuments(documents);
  }, [initialDocuments, setDocuments]);

  // Filter down the documents to be displayed by the user-entered filter (if any)
  const displayDocuments = useMemo(() => {
    if (!documents || !filterValue) {
      return documents;
    }

    // Make a filter expression, converting any whitespace into wildcards for a simple fuzzy-matching expression
    const filterExpressionString = filterValue
        .replace(/\s+/g, ' ')
        .split(' ')
        .map((part) => escapeRegExp(part))
        .join('.*');

    const filterExpression = new RegExp(filterExpressionString, 'gi');

    return documents.filter((doc) => filterExpression.test(doc.id) || filterExpression.test(doc.master_name));
  }, [documents, filterValue]);

  const handleFilterValueChange = useMemo(
      () =>
        debounce((event) => {
          setFilterValue(event.target.value);
        }, debounceDelay),
      [debounceDelay, setFilterValue],
  );

  const config = [
    {
      title: 'Master Name',
      field: 'master_name',
      headerClassName: classes.identityHeader,
      className: classes.longColumn,
      cellClassName: classes.identityCell,
    },
    {
      title: 'Carrier',
      field: 'carrier',
      headerClassName: classes.identityHeader,
      className: classes.mediumColumn,
      cellClassName: classes.identityCell,
    },
    {
      title: 'Resource Type',
      field: 'resource_type',
      headerClassName: classes.identityHeader,
      className: classes.mediumColumn,
      cellClassName: classes.identityCell,
    },
    {
      title: 'Version ID',
      field: 'id',
      headerClassName: classes.resourceHeader,
      className: classes.smallColumn,
      cellClassName: classes.resourceCell,
    },
    {
      title: 'Effective Date',
      field: 'effective_date',
      formatter: formatISODateString,
      headerClassName: classes.resourceHeader,
      className: classes.smallColumn,
      cellClassName: classes.resourceCell,
    },
    {
      title: 'Version Created At',
      field: 'resource_created_at',
      formatter: formatISODateString,
      headerClassName: classes.resourceHeader,
      className: classes.smallColumn,
      cellClassName: classes.resourceCell,
    },
    {
      title: 'App Association Created At',
      field: 'document_created_at',
      formatter: formatISODateString,
      className: classes.smallColumn,
      headerClassName: classes.documentHeader,
      cellClassName: classes.documentCell,
    },
    {
      title: 'App Association Updated At',
      field: 'document_updated_at',
      formatter: formatISODateString,
      className: classes.smallColumn,
      headerClassName: classes.documentHeader,
      cellClassName: classes.documentCell,
    },
  ];

  const Legend = () => {
    return (
      <Grid item container direction="row" alignItems="center" spacing={1}>
        <Grid item>
          <Typography className={classes.legendText}>Legend:</Typography>
        </Grid>
        <Grid item>
          <Chip label="Master" className={classes.legendResourceIdentity} />
        </Grid>
        <Grid item>
          <Chip label="Version" className={classes.legendResource} />
        </Grid>
      </Grid>
    );
  };

  const HeaderCell = ({title, headerClassName, className}) => {
    return (
      <Grid item container alignItems="center" className={clsx(className, headerClassName)}>
        <Typography variant="body2">{title}</Typography>
      </Grid>
    );
  };
  HeaderCell.propTypes = {
    title: PropTypes.string,
    headerClassName: PropTypes.string,
    className: PropTypes.string,
  };

  return (
    <Grid container direction="column" className={classes.root} spacing={2}>
      <Grid item container direction="row" alignItems="center" spacing={1}>
        <Grid item data-testid="document-viewer-list-table-title" style={{flexGrow: '1'}}>
          {tableTitle}
        </Grid>
        <Grid item>
          <Tooltip placement="bottom-start" title="Download data as CSV">
            <IconButton
              onClick={() =>
                downloadBlob(
                  `${appKey} ${new Date().toISOString()}.csv`,
                  toCSV(config, displayDocuments, true),
                  'text/csv;charset=utf-8;',
                )
              }
            >
              <SaveAltIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <TextField
            label="Filter By Document Name or ID"
            variant="outlined"
            size="small"
            className={classes.filterInput}
            onChange={handleFilterValueChange}
            data-testid="document-viewer-list-filter-input"
          />
        </Grid>
      </Grid>
      <Legend />
      <Grid item className={classes.listContainer}>
        <VirtualizedList
          items={displayDocuments}
          rowHeight={ROW_HEIGHT}
          header={
            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.tableHeader}
              data-testid="document-viewer-list-header"
            >
              {config.map((config, configIndex) => (
                <HeaderCell {...config} key={`headerColumn-${configIndex}`} />
              ))}
            </Grid>
          }
          rowTemplate={({index, item, style, key}) => (
            <DocumentsViewerListItem
              key={key}
              style={style}
              classes={classes}
              index={index}
              document={item}
              dataConfig={config}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

const documentShape = {
  id: PropTypes.number.isRequired,
  master_name: PropTypes.string,
  carrier: PropTypes.string,
  effective_date: PropTypes.instanceOf(Date),
  resource_type: PropTypes.string,
};

DocumentsViewerList.propTypes = {
  tableTitle: PropTypes.node,
  initialDocuments: PropTypes.arrayOf(PropTypes.shape(documentShape)).isRequired,
  debounceDelay: PropTypes.number,
  appKey: PropTypes.string,
};
