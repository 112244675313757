import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {TextField, Typography, Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import Edit from '@material-ui/icons/Edit';
import resources from 'api/resources';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    padding: '30px',
    borderRadius: '15px',
    boxShadow: theme.shadows[5],
  },
  heading: {
    marginTop: '0px',
    marginBottom: '15px',
  },
  button: {
    height: '40px',
    borderRadius: '4px',
    textTransform: 'none',
  },
  errorBar: {
    backgroundColor: '#FFE6E5',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    marginTop: 32,
  },
  label: {
    fontSize: '15px',
    marginTop: '15px',
    marginBottom: '5px',
  },
  textField: {
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#4096b4 !important',
    },
    '& :hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1f4958',
    },
  },
  buttonContainer: {
    marginTop: '30px',
  },
  createEditButton: {
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    width: '20px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.hoverMain,
    },
  },
  updateButton: {
    marginLeft: '0px',
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.hoverMain,
    },
  },
  cancelButton: {
    marginLeft: '20px',
    color: 'black',
  },
  editButton: {
    height: '20px',
    width: '20px',
    padding: '0px',
    margin: '0px',
    borderRadius: '4px',
    textTransform: 'none',
  },
}));

export default function AuthorManagementEditModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [englishName, setEnglishName] = React.useState('');
  const [englishNameError, setEnglishNameError] = React.useState(false);
  const [showAPIError, setShowAPIError] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    setEnglishName(props.name);
  };

  const handleClose = () => {
    setOpen(false);
    setEnglishNameError(false);
    setShowAPIError(false);
  };

  const handleEnglishNameChange = (event) => {
    setEnglishName(event.target.value);
    if (event.target.value) {
      setEnglishNameError(false);
    }
  };

  function handleCancel() {
    setEnglishName('');
    handleClose();
  }

  const handleSubmit = async () => {
    if (!englishName.trim()) {
      setEnglishNameError(true);
    }
    if (englishName.trim()) {
      try {
        console.log(props);
        await resources.updateAuthor(props.authorId, {
          name: englishName,
        });
        props.addEditAuthor(props.authorId, {name: englishName});
        handleClose();
      } catch (error) {
        setShowAPIError(true);
      }
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        startIcon={<Edit />}
        onClick={handleOpen}
        disableElevation
        className={`${classes.createEditButton} ${classes.editButton}`}
        disabled
      >
        Edit
      </Button>
      <Modal open={open} onClose={handleClose}>
        <div className={classes.modal}>
          <h1 className={classes.heading}>Update Author</h1>
          <Typography variant="h6" className={classes.label}>
            Name
          </Typography>
          <TextField
            variant="outlined"
            value={englishName}
            onChange={handleEnglishNameChange}
            error={englishNameError}
            helperText={englishNameError ? 'This field is required' : ''}
            className={classes.textField}
          />
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              disableElevation
              className={`${classes.button} ${classes.updateButton}`}
              onClick={handleSubmit}
            >
              Update
            </Button>
            <Button
              variant="contained"
              onClick={handleCancel}
              disableElevation
              className={`${classes.button} ${classes.cancelButton}`}
            >
              Cancel
            </Button>
          </div>
          {showAPIError && (
            <Typography color="error" className={classes.errorBar}>
              Oops! An Unkown Erorr as occured. Please try again.
            </Typography>
          )}
        </div>
      </Modal>
    </div>
  );
}

AuthorManagementEditModal.propTypes = {
  name: PropTypes.string.isRequired,
  authorId: PropTypes.number.isRequired,
  addEditAuthor: PropTypes.func.isRequired,
};
