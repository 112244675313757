import fetch from 'api/fetch';

// Function to create data for a row
export function createData(id, name, displayName, createdAt, updatedAt) {
  return {id, name, displayName, createdAt, updatedAt};
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const formattedDate = date.toLocaleString('en-US', options);

  return formattedDate;
}

export const linesOfBusiness = async () => {
  try {
    const baseURL = process.env.REACT_APP_DIALOG_BASE_URL;
    const url = '/lines_of_business';
    const method = 'GET';

    // API request from 'fetch.js'
    const response = await fetch(baseURL, url, method);

    // linesOfBusinessData here
    const linesOfBusinessData = response.data;

    // Map the data to create rows for the table
    const rowsData = linesOfBusinessData.map((line) =>
      createData(line.id, line.name, line.display_name, formatDate(line.created_at), formatDate(line.updated_at)),
    );

    return rowsData;
  } catch (error) {
    console.error('Error fetching data from API:', error);
    throw error;
  }
};

export const postLineOfBusiness = async (rows, name, displayName) => {
  try {
    const baseURL = process.env.REACT_APP_DIALOG_BASE_URL;
    const url = '/management/lines-of-business';
    const method = 'POST';
    const requestBody = {
      name: name,
      display_name: {
        en: displayName,
        fr: '',
      },
    };

    // Call the fetch function to perform the POST request
    const response = await fetch(baseURL, url, method, requestBody);
    const newLineOfBusinessData = response.data;

    const newId = rows.length === 0 ? 1 : Math.max(...rows.map((row) => row.id)) + 1;

    // API returns the data for the new line of business
    return createData(
        newId,
        name,
        newLineOfBusinessData.display_name.en,
        formatDate(newLineOfBusinessData.created_at),
        formatDate(newLineOfBusinessData.updated_at),
    );
  } catch (error) {
    console.error('Error creating new line of business:', error);
    throw error;
  }
};

export const putLineOfBusiness = async (id, name, displayName) => {
  try {
    const currentDate = formatDate(new Date()); // Get the current date

    const baseURL = process.env.REACT_APP_DIALOG_BASE_URL;
    const url = `/management/lines-of-business/${id}`;
    const method = 'PUT';
    const requestBody = {
      name: name,
      display_name: {
        en: displayName,
        fr: '',
      },
      updated_at: currentDate,
    };

    // Call the fetch function to perform the PUT request
    const response = await fetch(baseURL, url, method, requestBody);
    const updatedLineOfBusinessData = response.data;

    // API returns the data for the updated line of business
    return createData(
        updatedLineOfBusinessData.id,
        name,
        updatedLineOfBusinessData.display_name.en,
        formatDate(updatedLineOfBusinessData.created_at),
        formatDate(updatedLineOfBusinessData.updated_at),
    );
  } catch (error) {
    console.error('Error updating line of business:', error);
    throw error;
  }
};
