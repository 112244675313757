export const HEADER_HEIGHT = '64px';

export const PRODUCTION_ALERT_HEIGHT = '108px';

export const TEXT_INPUT_ONCHANGE_DEBOUNCE_DELAY = 600;

export const LABEL_SYNC_JOBS_ENDPOINT = 'label-sync/jobs';
export const LABEL_SYNC_POLLING_INTERVAL = 5000;
export const SHEET_DIFF_POLLING_INTERVAL = 5000;
export const SHEET_GENERATION_POLLING_INTERVAL = 5000;
export const DOCUMENT_MANAGEMENT_SHEETS_POLLING_INTERVAL = 300000;

export const DEFAULT_TOOL_ROUTE = 'google-sheet-generation';
export const LIST_ROW_HEIGHT = 46;

export const COMPONENT_STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const HTTP_RESPONSE_STATUS_CODES = {
  OK: 200,
  // Client Errors
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE_CONTENT: 422,
  // Server Errors
  INTERNAL_SERVER_ERROR: 500,
};

// This defines how often to check if a new token has been collected, in ms
export const REFRESH_TOKEN_WAIT_TIME = 100;

export const REFRESH_TOKEN_ENDPOINT = '/token/refresh';

// The timeout for the refresh wait and check loop
export const MAX_REFRESH_TOKEN_WAIT_TIME = 20000;
