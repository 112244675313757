/**
 * Get a list of all data from a paged API function, the return must have hasMore and offset in a returned object
 * along with a list associated to some key
 * @param   {Function} origFunc      The function used to fetch subsets of data
 * @param   {Number}   perFetchLimit The maximum number of items to fetch at a time
 * @param   {String}   dataKey       The key to access data in the function return
 * @returns {Array}                  The combined list of data
 */
async function fetchAll(origFunc, perFetchLimit, dataKey = 'items') {
  let offset = 0;
  let hasMore = true;
  let data = [];
  while (hasMore) {
    try {
      const funcReturn = await origFunc(offset, perFetchLimit);
      data = data.concat(funcReturn[dataKey]);
      hasMore = funcReturn.hasMore;
      offset += perFetchLimit;
    } catch (e) {
      console.error('Error during fetchall', e);
      hasMore = false;
    }
  }

  return data;
}

export default fetchAll;
