import React, {useEffect, useState, useRef} from 'react';
import {useNavigate, Navigate, useLocation} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import logo from 'static/pronavigator-logo.svg';
import {TextField, Button, CircularProgress, Typography} from '@material-ui/core';
import {useFormik} from 'formik';
import auth from 'api/auth';
import {isError} from 'lib/helper';
import {DEFAULT_TOOL_ROUTE} from 'lib/constants';
import Grid from '@material-ui/core/Grid';
import WaitingScreen from 'components/Common/WaitingScreen';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  logo: {
    width: 200,
    marginBottom: theme.spacing(1),
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 350,
  },
  submit: {
    padding: '13px 0px',
    textTransform: 'inherit',
    width: '100%',
    fontSize: 15,
    marginTop: 18,
  },
  // TODO: Hidden styling has to be replaced by hidden attribute on password field; test cases to be added accordingly
  hidden: {
    display: 'none',
  },
  superAdminError: {
    color: '#f44336',
    fontSize: '0.75rem',
    fontWeight: 400,
  },
}));

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = auth.isLoggedIn();

  const passwordInputElement = useRef(null);

  const [redirect, setRedirect] = useState(false);
  const [requiresPassword, setRequiresPassword] = useState(false);
  const [updatingToken, setUpdatingToken] = useState(false);
  const [loginError, setLoginError] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const refreshToken = params.get('refresh_token');
    if (token && refreshToken && auth.validate(token) && auth.validate(refreshToken)) {
      setUpdatingToken(true);
      auth
          .updateToken(token, refreshToken)
          .then(async () => {
            const primaryUserId = auth.getUserPrimaryID();
            await auth.validatePronavSuperAdmin(primaryUserId);
          })
          .then(() => {
            setRedirect(true);
          })
          .catch((error) => setLoginError(error.message))
          .finally(() => setUpdatingToken(false));
    }
  }, [location]);

  const onSubmitValidator = (values) => {
    const errors = {};

    if (!values.primaryUserId) {
      errors.primaryUserId = 'Enter your email address';
    }

    if (requiresPassword && values.password.length < 1) {
      errors.password = 'Enter your password';
    }

    return errors;
  };

  const onSubmitHandler = async (values, {setFieldError, setStatus, setSubmitting}) => {
    setSubmitting(true);

    if (requiresPassword) {
      auth
          .login(values)
          .then(() => {
            navigate(DEFAULT_TOOL_ROUTE);
            setSubmitting(false);
            setRedirect(true);
          })
          .catch((error) => {
            console.error(error.message);
            setSubmitting(false);
            setFieldError('password', 'Failed to log in');
          });
    } else {
      auth
          .getAuthType(values.primaryUserId)
          .then((response) => {
            const type = response['auth_type'];

            if (type === 'sso') {
              window.location.replace(response['authnRequest']);
            } else {
              setRequiresPassword(true);
              setSubmitting(false);
              passwordInputElement.current.focus();
            }
          })
          .catch((error) => {
            console.error(error);
            setSubmitting(false);
            setFieldError('password', 'Failed to log in');
          })
          .finally(() => setSubmitting(false));
    }
  };

  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      primaryUserId: '',
      password: '',
    },
    validate: onSubmitValidator,
    onSubmit: onSubmitHandler,
  });

  return redirect ? (
    <Navigate to={DEFAULT_TOOL_ROUTE} replace />
  ) : updatingToken ? (
    <WaitingScreen text="Please wait while we log you in..." />
  ) : isLoggedIn ? (
    <Navigate to={DEFAULT_TOOL_ROUTE} replace />
  ) : (
    <div className={classes.root}>
      <img src={logo} alt="Sage" className={classes.logo} />
      <Typography variant="h4" component="h1">
        ProNavigator Internal Tools Login
      </Typography>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <Grid container>
          <TextField
            id="primaryUserId"
            label="Email Address"
            fullWidth
            error={loginError ? loginError : isError(formik, 'primaryUserId')}
            onChange={formik.handleChange}
            value={formik.values.primaryUserId}
            autoComplete="email"
            helperText={formik.errors.primaryUserId}
          />
          {loginError && <Typography className={classes.superAdminError}>{loginError}</Typography>}
        </Grid>
        <Grid container className={!requiresPassword ? classes.hidden : ''}>
          <TextField
            id="password"
            label="Password"
            type="password"
            autoComplete="current-password"
            fullWidth
            error={isError(formik, 'password')}
            onChange={formik.handleChange}
            value={formik.values.password}
            helperText={formik.errors.password}
            inputProps={{
              ref: passwordInputElement,
            }}
          />
        </Grid>
        <Grid item container>
          {requiresPassword ? (
            <Button
              name="log in"
              type="submit"
              variant="contained"
              color="primary"
              disabled={formik.isSubmitting}
              className={classes.submit}
            >
              {formik.isSubmitting ? <CircularProgress style={{color: 'inherit'}} size={24} /> : 'Login'}
            </Button>
          ) : (
            <Button
              name="continue"
              type="submit"
              variant="contained"
              color="primary"
              disabled={formik.isSubmitting}
              className={classes.submit}
            >
              {formik.isSubmitting ? <CircularProgress style={{color: 'inherit'}} size={24} /> : 'Continue'}
            </Button>
          )}
        </Grid>
      </form>
    </div>
  );
}
