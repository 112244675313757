import {React, useState} from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import {makeStyles, Typography} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import app from 'api/app';
import {PropTypes} from 'prop-types';
import ErrorOutline from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 'auto',
    backgroundColor: 'white',
    padding: '30px',
    borderRadius: '15px',
  },
  bottomButtons: {
    height: 50,
    width: 150,
  },
  title: {
    marginBottom: 16,
  },
  fieldHeaders: {
    marginBottom: 8,
  },
  fields: {
    marginBottom: 16,
    borderColor: '#4095b3',
  },
  errorBar: {
    backgroundColor: '#FFE6E5',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    marginTop: 32,
  },
}));

export default function AppManagementModal(props) {
  const classes = useStyles();
  const [appId, setAppId] = useState('sage-');
  const [desc, setDesc] = useState('');
  const [hasLanguages, setHasLanguages] = useState([false, false]);
  const [appSubType, setAppSubType] = useState('broker');
  const [alertIfEmpty, setAlertIfEmpty] = useState(false);
  const [showAPIError, setShowAPIError] = useState(false);

  const handleAppIdChange = (appSuffix) => {
    setAppId('sage-' + appSuffix);
  };

  async function handleSubmit() {
    if (hasLanguages[0] === false && hasLanguages[1] === false) {
      setAlertIfEmpty(true);
      return;
    }

    if (appId === '' || desc === '') {
      setAlertIfEmpty(true);
      return;
    }
    const langs = [];
    if (hasLanguages[0] === true) {
      langs.push('en');
    }
    if (hasLanguages[1] === true) {
      langs.push('fr');
    }
    try {
      const response = await app.postSageApp(appId, desc, langs, appSubType);
      if (response.message === 'Success.') {
        props.onSubmit();
        props.onClose();
      } else {
        // On API Error
        setShowAPIError(true);
      }
    } catch (error) {
      // This would technically be a network error, but displays same message
      setShowAPIError(true);
    }
  }
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box className={classes.modal}>
        <Typography variant="h4" component="h1" className={classes.title}>
          Create an App
        </Typography>
        <Typography variant="h6" className={classes.fieldHeaders}>
          App ID
        </Typography>
        <TextField
          id="appId"
          variant="outlined"
          className={classes.fields}
          fullWidth={true}
          InputProps={{
            startAdornment: <InputAdornment position="start">sage-</InputAdornment>,
          }}
          value={appId.slice(5)}
          onChange={(event) => {
            handleAppIdChange(event.target.value);
          }}
          error={alertIfEmpty && appId === 'sage-'}
          helperText={alertIfEmpty && appId === 'sage-' ? 'This field is required.' : ''}
          data-testid="appId"
        />
        <Typography variant="h6" className={classes.fieldHeaders}>
          Description
        </Typography>
        <TextField
          id="desc"
          variant="outlined"
          className={classes.fields}
          fullWidth={true}
          value={desc}
          onChange={(event) => {
            setDesc(event.target.value);
          }}
          error={alertIfEmpty && desc === ''}
          helperText={alertIfEmpty && desc === '' ? 'This field is required.' : ''}
          data-testid="desc"
        />
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div>
            <Typography variant="h6" className={classes.fieldHeaders}>
              Languages
            </Typography>
            <FormControlLabel
              value="top"
              control={
                <Checkbox
                  style={{color: '#4095b3'}}
                  checked={hasLanguages[0]}
                  onChange={(event) => {
                    setHasLanguages([event.target.checked, hasLanguages[1]]);
                  }}
                />
              }
              label="English"
              labelPlacement="left"
            />
            <FormControlLabel
              value="top"
              control={
                <Checkbox
                  checked={hasLanguages[1]}
                  style={{color: '#4095b3'}}
                  onChange={(event) => {
                    setHasLanguages([hasLanguages[0], event.target.checked]);
                  }}
                />
              }
              label="French"
              labelPlacement="left"
            />
            {alertIfEmpty && !hasLanguages.includes(true) && (
              <FormHelperText style={{color: '#f44336'}}>Please select at least one!</FormHelperText>
            )}
          </div>
          <div style={{marginLeft: 110, alignItems: 'right'}}>
            <Typography variant="h6" className={classes.fieldHeaders}>
              Sub-type
            </Typography>
            <FormControl fullWidth style={{marginTop: -16}}>
              <InputLabel id="subType"></InputLabel>
              <Select
                labelId="subType"
                id="subType"
                value={appSubType}
                onChange={(event) => {
                  setAppSubType(event.target.value);
                }}
                data-testid="subType"
              >
                <MenuItem value="broker">Broker</MenuItem>
                <MenuItem value="carrier">Carrier</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 16}}>
          <Button
            variant="contained"
            className={classes.bottomButtons}
            onClick={props.onClose}
            style={{backgroundColor: 'white', color: '#4095b3', border: '1px solid #4095b3'}}
          >
            Cancel
          </Button>
          <Box width="50px"></Box>
          <Button
            variant="contained"
            className={classes.bottomButtons}
            style={{backgroundColor: '#4095b3', color: 'white'}}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
        {showAPIError && (
          <div className={classes.errorBar}>
            <ErrorOutline color="error" style={{marginRight: '10'}} />
            <FormHelperText style={{color: 'black', fontSize: 13}}>
              Oops! An unknown error occured. Try again or come back later.
            </FormHelperText>
          </div>
        )}
      </Box>
    </Modal>
  );
}

AppManagementModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
