/**
 * Takes a date object and generates a ISO timestamp from it
 * @param   {Date}   date The date to convert to a string
 * @returns {String}      The ISO string
 */
export function formatISODateString(date) {
  if (!date) {
    return null;
  }
  const isoString = date.toISOString();
  return isoString.substring(0, isoString.indexOf('T'));
}

/**
 * Takes a timestamp and then generates a localized version intended for display
 * @param   {String} dateString The string to convert to a date
 * @returns {String}            The localized and humanized version of the timestamp
 */
export function convertISODateStringToHuman(dateString) {
  return new Date(dateString).toLocaleString(undefined, {
    hour12: true,
    timeZoneName: 'short',
  });
}

/**
 * Takes a timestamp and then generates a localized version with the provided options
 * @param   {String} dateString The string to convert to a date
 * @returns {String}            The localized and humanized version of the timestamp
 */
export function convertISODateStringToHumanReadableWithOptions(dateString, options) {
  return new Date(dateString).toLocaleString(undefined, options);
}

/**
 * Takes a timestamp and then generates a localized version intended for display
 * With the format: 'December 19, 2012 at 10:00 PM EST'
 * @param   {String} dateString The string to convert to a date
 * @returns {String}            The localized and humanized version of the timestamp
 */
export function convertISODateStringToLongDayFormat(dateString) {
  const options = {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  };
  return convertISODateStringToHumanReadableWithOptions(dateString, options);
}
