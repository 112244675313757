import React from 'react';
import {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import regionManagementAPI from 'api/regionManagement';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  subTitle: {
    fontStyle: 'italic',
  },
  search: {
    display: 'block',
  },
  buttonContainer: {
    display: 'block',
  },
  headContainer: {
    display: 'flex',
  },
  button: {
    marginLeft: 928,
  },
  idCell: {
    width: 300,
  },
  enCell: {
    width: 300,
  },
  frCell: {
    width: 300,
  },
  tagCell: {
    width: 300,
  },
});

function createData(id, nameEN, nameFR, tagId) {
  return {id, nameEN, nameFR, tagId};
}

export default function RegionManagement() {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [idAscending, setIdAscending] = useState(true);
  const [nameAscending, setNameAscending] = useState(false);
  const [tagAscending, setTagAscending] = useState(false);
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [addData, setAddData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const regions = await regionManagementAPI.regionManagement();
        if (regions.length === 0) {
          throw new Error('No region found');
        }
        setRows(
            regions.map((regions) => {
              return createData(regions.id, regions.name, '', regions.tag_id);
            }),
        );
        setData(
            regions.map((regions) => {
              return createData(regions.id, regions.name, '', regions.tag_id);
            }),
        );
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  // SORTING
  function handleSortId() {
    if (idAscending === false) {
      const sortedId = [...data].sort((a, b) => a.id - b.id);
      setData([...sortedId]);
      setIdAscending(true);
    } else {
      const sortedId = [...data].sort((a, b) => b.id - a.id);
      setData([...sortedId]);
      setIdAscending(false);
    }
    setNameAscending(false);
    setTagAscending(false);
  }

  function handleSortENName() {
    if (nameAscending === false) {
      const sortedName = [...data].sort((a, b) => a.nameEN.localeCompare(b.nameEN));
      setData([...sortedName]);
      setNameAscending(true);
    } else {
      const sortedName = [...data].sort((a, b) => b.nameEN.localeCompare(a.nameEN));
      setData([...sortedName]);
      setNameAscending(false);
    }
    setIdAscending(false);
    setTagAscending(false);
  }

  function handleSortFRName() {
    if (nameAscending === false) {
      const sortedName = [...data].sort((a, b) => a.nameFR.localeCompare(b.nameFR));
      setData([...sortedName]);
      setNameAscending(true);
    } else {
      const sortedName = [...data].sort((a, b) => b.nameFR.localeCompare(a.nameFR));
      setData([...sortedName]);
      setNameAscending(false);
    }
    setIdAscending(false);
    setTagAscending(false);
  }

  function handleSortTag() {
    if (tagAscending === false) {
      const sortedTag = [...data].sort((a, b) => a.tagId - b.tagId);
      setData([...sortedTag]);
      setTagAscending(true);
    } else {
      const sortedTag = [...data].sort((a, b) => b.tagId - a.tagId);
      setData([...sortedTag]);
      setTagAscending(false);
    }
    setIdAscending(false);
    setNameAscending(false);
  }
  // END OF SORTING

  // SEARCHING
  function handleSearch() {
    let reference = [];
    if (addData.length === 0) {
      reference = [...rows];
    } else {
      reference = [...addData];
    }
    const filter = document.getElementById('myInput').value;
    let myTable = [];
    if (filter.length === 0) {
      myTable = [...reference];
    } else {
      reference.forEach(compare);
    }
    setData([...myTable]);

    function compare(item) {
      if (
        item.nameEN.toLowerCase().includes(filter) ||
        item.nameFR.toLowerCase().includes(filter) ||
        item.id.toString().includes(filter) ||
        item.tagId.toString() === filter
      ) {
        myTable.push(item);
      }
    }
  }
  // END OF SEARCHING

  // PAGING
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // END OF PAGING

  // FORM POPUP
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  function handleSubmit() {
    const enVal = document.getElementById('nameEN').value;
    const frVal = document.getElementById('nameFR').value;
    const newData = createData(addData.length + 1, enVal, frVal, addData.length + 1);
    setAddData((prevData) => [...prevData, newData]);
    handleClose();
  }
  function handleDisplay() {
    setData([...addData]);
  }

  function RegionModel() {
    return (
      <>
        <div className={classes.button}>
          <Button variant="outlined" onClick={handleClickOpen} disabled>
            New Region
          </Button>
          <Button variant="outlined" onClick={handleDisplay} disabled>
            Display
          </Button>
        </div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Enter Names</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="nameEN"
              label="English Name"
              type="text"
              fullWidth
              variant="standard"
              inputProps={{'data-testid': 'en-input'}}
            />
            <TextField
              margin="dense"
              id="nameFR"
              label="French Name"
              type="text"
              fullWidth
              variant="standard"
              inputProps={{'data-testid': 'fr-input'}}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit}>Submit</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  // END OF FORM

  return (
    <div>
      <div className={classes.headContainer}>
        <Typography variant="h4">Region Management</Typography>
        <RegionModel />
      </div>
      <Typography variant="subtitle1" className={classes.subTitle}>
        Manage your region
      </Typography>
      <div className={classes.search}>
        <input id="myInput" placeholder="Search" data-testid="mySearch" />
        <Button variant="contained" color="primary" onClick={handleSearch}>
          Search
        </Button>
      </div>
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" onClick={handleSortId}>
          Sort ID
        </Button>
        <Button variant="contained" color="primary" onClick={handleSortENName}>
          Sort Name EN
        </Button>
        <Button variant="contained" color="primary" onClick={handleSortFRName}>
          Sort Name FR
        </Button>
        <Button variant="contained" color="primary" onClick={handleSortTag}>
          Sort Tag ID
        </Button>
      </div>
      <TableContainer component={Paper} data-testid="table">
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.idCell}>ID</TableCell>
              <TableCell align="left" className={classes.enCell}>
                English Name
              </TableCell>
              <TableCell align="left" className={classes.frCell}>
                French Name
              </TableCell>
              <TableCell align="left" className={classes.tagCell}>
                Tag ID
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data).map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="left">{row.nameEN}</TableCell>
                <TableCell align="left">{row.nameFR}</TableCell>
                <TableCell align="left">{row.tagId}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
