import React from 'react';
import PropTypes from 'prop-types';
import {Alert} from '@material-ui/lab';
import Output from 'components/Common/Output';

function SheetDiff({diff}) {
  return diff ? (
    <Output errors={diff.errors} actions={diff.actions} warnings={diff.warnings} />
  ) : (
    <Alert severity="warning">No change information is available.</Alert>
  );
}

SheetDiff.propTypes = {
  diff: PropTypes.shape({
    errors: PropTypes.array,
    actions: PropTypes.array,
    warnings: PropTypes.array,
  }),
};

export default SheetDiff;
