import React from 'react';
import PropTypes from 'prop-types';

import {FixedSizeList} from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  autoSizerContainer: {
    flexGrow: 1,
    height: '100%',
  },
  progress: {
    height: '100%',
  },
}));

/**
 * @interface {RowTemplate}
 * @param {Object} props
 * @param {Object} props.item  - An individual data item to be rendered for this row
 * @param {*}      props.key   - A Key to apply to the component, to aid in rendering performance
 * @param {Object} props.style - A style dictionary to apply to the root of the rendered row
 *                               This is *required* for proper rendering of the list
 */

/**
 * Reusable component to simplify presenting a virtualized list of data
 * @param {Object}              props
 * @param {Array<*>}            props.items           - The data to be rendered in the list
 * @param {Number}              props.rowHeight       - The height of each rendered row (including spacing)
 * @param {ReactElement}        [props.header]        - An optional component/element to render as a header of the list
 * @param {Function}            props.rowTemplate     - A component to use as a template for each row
 *                                                      This component should implement the `RowTemplate` interface
 * @param {Boolean}             [props.showSpinner]   - Whether a spinner should be rendered in place of the list body
 *                                                      This can be used to indicate data is loading or being refreshed
 * @param {String|ReactElement} [props.noDataContent] - The content to display when `items` is nil or is empty
 *                                                      This content will _not_ be rendered if `showSpinner == true`
 */
function VirtualizedList({items = [], rowHeight, header, rowTemplate, showSpinner = false, noDataContent = null}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {header}
      {showSpinner ? (
        <Grid container justifyContent="center" alignItems="center" className={classes.progress}>
          <CircularProgress />
        </Grid>
      ) : !items || items.length === 0 ? (
        noDataContent || 'No Data'
      ) : (
        <div className={classes.autoSizerContainer}>
          <AutoSizer defaultHeight={2 * rowHeight}>
            {({height, width}) => (
              <FixedSizeList
                height={height}
                width={width}
                itemCount={items.length}
                itemData={items}
                itemSize={rowHeight}
              >
                {({data, index, style}) =>
                  rowTemplate({
                    index: index,
                    item: data[index],
                    key: attemptGetKey(data[index], index),
                    style,
                  })
                }
              </FixedSizeList>
            )}
          </AutoSizer>
        </div>
      )}
    </div>
  );
}

VirtualizedList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  rowHeight: PropTypes.number,
  header: PropTypes.element,
  rowTemplate: PropTypes.func,
  showSpinner: PropTypes.bool,
  noDataContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default VirtualizedList;

/**
 * Helper function to attempt to generate a unique key for the given item.
 * If the provided item is an object with an `id` property, then the value
 * of this property is used. Otherwise the index of the item is returned.
 *
 * @param {Object} item  - The item to generate the key for
 * @param {Number} index - The current index of the item
 * @returns {*} The key for the item.
 */
function attemptGetKey(item, index) {
  if (Object.hasOwnProperty.call(item, 'id')) {
    return item.id;
  }
  return index;
}
