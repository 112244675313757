import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, Select, Checkbox, Chip, Input, InputLabel, MenuItem, ListItemText} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: 2,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export default function MultiSelectDropdown({value, label, id, handleChange, options}) {
  const classes = useStyles();

  return (
    <>
      <InputLabel id={`mutiple-${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`mutiple-${id}-label`}
        id={id}
        multiple
        value={value}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip key={value} label={value} className={classes.chip} />
            ))}
          </div>
        )}
        >
        {options.map((option) => (
          <MenuItem key={option.key} value={option.value}>
            <Checkbox color="primary" checked={value.indexOf(option.value) > -1} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

MultiSelectDropdown.propTypes = {
  value: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
  ).isRequired,
};
