import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import ListItem from 'components/Common/ListItem';
import {convertISODateStringToHuman} from 'lib/timeHelpers';

function SyncHistoryListItem({style, classes, index, syncJob, setDisplayedSyncJob}) {
  const formattedStartString = convertISODateStringToHuman(syncJob.startedAt + 'Z');
  const formattedEndString = syncJob.completedAt ? convertISODateStringToHuman(syncJob.completedAt + 'Z') : 'N/A';

  const onClickHandler = () => {
    if (syncJob.diff.actions.length > 0 || syncJob.diff.errors.length > 0 || syncJob.diff.warnings.length > 0) {
      setDisplayedSyncJob(syncJob);
    }
  };

  const runType = syncJob.dryRun === 1 ? 'Dry' : 'Applied';

  return (
    <ListItem
      style={style}
      index={index}
      onClick={onClickHandler}
      item={syncJob}
      columns={[
        {
          id: 1,
          render: (item) => item.id,
          className: classes.idColumn,
        },
        {
          id: 2,
          render: (item) => (
            <Typography variant="body2" noWrap>
              {item.status}
            </Typography>
          ),
          className: classes.statusColumn,
          tooltip: (item) => item.status,
        },
        {
          id: 3,
          render: () => (
            <Typography variant="body2" noWrap>
              {runType}
            </Typography>
          ),
          className: classes.dryRunColumn,
          tooltip: () => runType,
        },
        {
          id: 4,
          render: (item) => (
            <Typography variant="body2" noWrap>
              {item.sheetName}
            </Typography>
          ),
          className: classes.sheetNameColumn,
          tooltip: (item) => item.sheetName,
        },
        {
          id: 5,
          render: () => (
            <Typography variant="body2" noWrap>
              {formattedStartString}
            </Typography>
          ),
          className: classes.startedAtColumn,
          tooltip: () => formattedStartString,
        },
        {
          id: 6,
          render: () => (
            <Typography variant="body2" noWrap>
              {formattedEndString}
            </Typography>
          ),
          className: classes.finishedAtColumn,
          tooltip: () => formattedEndString,
        },
      ]}
    />
  );
}

const diffShape = {
  actions: PropTypes.arrayOf(PropTypes.string).isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  warnings: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const syncJobShape = {
  id: PropTypes.number.isRequired,
  dryRun: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  sheetName: PropTypes.string.isRequired,
  diff: PropTypes.shape(diffShape),
  startedAt: PropTypes.string.isRequired,
  completedAt: PropTypes.string,
};

SyncHistoryListItem.propTypes = {
  style: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  syncJob: PropTypes.shape(syncJobShape).isRequired,
  setDisplayedSyncJob: PropTypes.func.isRequired,
};

export default SyncHistoryListItem;
