import React from 'react';
import PropTypes from 'prop-types';

function TabItem({children, index, selectedTab, keepMounted, ...other}) {
  const shouldRenderChildren = keepMounted || index === selectedTab;

  return (
    <div
      role="tabpanel"
      hidden={index !== selectedTab}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      >
      {shouldRenderChildren && children}
    </div>
  );
}

TabItem.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number,
  selectedTab: PropTypes.number,
  keepMounted: PropTypes.bool,
};

TabItem.defaultProps = {
  keepMounted: true,
};

export default TabItem;
