import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

import sheetModel from 'components/DocumentManagement/SheetModel';
import {DocumentManagementSheetStatus} from 'api/documentManagement';

function SheetStatusChip({sheet}) {
  function getColourForStatus(status) {
    switch (sheet.status) {
      case DocumentManagementSheetStatus.ACTIVE:
        return 'primary';
      case DocumentManagementSheetStatus.IMPORTING:
      case DocumentManagementSheetStatus.PREVIEWING:
        return 'secondary';
      default:
        return 'default';
    }
  }

  const colour = getColourForStatus(sheet.status);

  return <Chip label={sheet.status} color={colour}></Chip>;
}

SheetStatusChip.propTypes = {
  sheet: PropTypes.shape(sheetModel).isRequired,
};

export default SheetStatusChip;
