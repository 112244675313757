import React, {Fragment, useMemo, useRef, useState, useEffect} from 'react';

import clsx from 'clsx';
import isEmpty from 'lodash.isempty';
import {useFormik} from 'formik';

import {makeStyles, TextField, Button, CircularProgress, Divider} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import Output from 'components/Common/Output';

import {isError} from 'lib/helper';
import labelSync, {LabelSyncJobStatus} from 'api/labelSync';

import {LABEL_SYNC_POLLING_INTERVAL} from 'lib/constants';

const POLLING_STATUSES = [LabelSyncJobStatus.PENDING, LabelSyncJobStatus.IN_PROGRESS];

const helpDocumentURL =
  'https://docs.google.com/document/d/1v92V-YN0EUZZ42ylPvSD4cUDqqSphmyANqKwmAlrxx8/edit?usp=sharing';

const previewTask = 'preview';
const applyTask = 'apply';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    height: '100%',
    padding: '30px 50px',
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: 25,
  },
  button: {
    padding: '13px 0px',
    textTransform: 'inherit',
    fontSize: 15,
    marginTop: 18,
    marginRight: 15,
    width: 250,
  },
  applyChanges: {
    background: theme.palette.warning.main,
    '&:hover': {
      background: theme.palette.warning.dark,
    },
  },
}));

const onSubmitValidator = (values) => {
  const errors = {};

  if (!values.sheetName) {
    errors.sheetName = 'Enter a Sheet Name';
  }

  return errors;
};

function StartSyncJobTab() {
  const classes = useStyles();
  const [job, setJob] = useState(null);
  const [error, setError] = useState(null);
  const [currentTask, setCurrentTask] = useState(null);

  const pollingInterval = useRef();
  useEffect(() => {
    return () => {
      clearInterval(pollingInterval.current);
    };
  }, []);

  const pollLabelSyncJob = (jobId) => {
    return new Promise((resolve, reject) => {
      pollingInterval.current = setInterval(async () => {
        try {
          const currentJob = await labelSync.getJob(jobId);
          setJob(currentJob);

          if (!POLLING_STATUSES.includes(currentJob.status)) {
            clearInterval(pollingInterval.current);
            resolve();
          }
        } catch (error) {
          reject(error);
        }
      }, LABEL_SYNC_POLLING_INTERVAL);
    });
  };

  const handleLabelSyncJob = async (values, setSubmitting, dryRun) => {
    try {
      setSubmitting(true);
      setJob(null);
      setError(null);

      const newJob = await labelSync.createJob(values.sheetName, dryRun);
      setJob(newJob);

      if (POLLING_STATUSES.includes(newJob.status)) {
        await pollLabelSyncJob(newJob.id);
      }
    } catch (error) {
      console.error('error: ', error.message);
      setError(error.message);
    } finally {
      setCurrentTask(null);
      setSubmitting(false);
    }
  };

  const onSubmitHandler = (values, {setSubmitting}) => {
    setCurrentTask(previewTask);
    return handleLabelSyncJob(values, setSubmitting, true);
  };

  const formik = useFormik({
    initialValues: {sheetName: 'Label Sync Master Sheet'},
    validate: onSubmitValidator,
    onSubmit: onSubmitHandler,
  });

  const onApplyChangesClick = async () => {
    // Formik only validates on submit, so we need to manually validate here
    const errors = await formik.validateForm();

    if (!isEmpty(errors)) {
      return;
    }

    setCurrentTask(applyTask);
    return handleLabelSyncJob(formik.values, formik.setSubmitting, false);
  };

  const actions = useMemo(() => {
    const newActions = job ? job.diff.actions : [];
    return newActions;
  }, [job]);

  const errors = useMemo(() => {
    const newErrors = job ? job.diff.errors : [];
    if (error) {
      newErrors.push(error);
    } else if (
      newErrors.length === 0 &&
      (job?.status === LabelSyncJobStatus.EXCEPTION || job?.status === LabelSyncJobStatus.FAILED)
    ) {
      newErrors.push(`Job finished with status '${job.status}' due to an unknown error`);
    }
    return newErrors;
  }, [job, error]);

  const warnings = useMemo(() => {
    return job ? job.diff.warnings : [];
  }, [job]);

  const shouldShowOutput = useMemo(() => {
    if (formik.isSubmitting) {
      return false;
    }
    return actions || errors || warnings;
  }, [formik.isSubmitting, actions, errors, warnings]);

  return (
    <Fragment>
      <Alert severity="info">
        Click{' '}
        <a href={helpDocumentURL} target="_blank" rel="noreferrer">
          here
        </a>{' '}
        for help and instructions.
      </Alert>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <TextField
          id="sheetName"
          label="Label Sync Sheet Name"
          error={isError(formik, 'sheetName')}
          onChange={formik.handleChange}
          value={formik.values.sheetName}
          autoComplete="sheetName"
          helperText={formik.errors.sheetName}
        />
        <div>
          <Button
            name="dry run"
            type="submit"
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting}
            className={classes.button}
          >
            {formik.isSubmitting && currentTask === previewTask ? (
              <CircularProgress style={{color: 'inherit'}} size={24} />
            ) : (
              'Dry Run'
            )}
          </Button>
          <Button
            name="apply"
            type="button"
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting}
            className={clsx(classes.button, classes.applyChanges)}
            onClick={onApplyChangesClick}
          >
            {formik.isSubmitting && currentTask === applyTask ? (
              <CircularProgress style={{color: 'inherit'}} size={24} />
            ) : (
              'Apply Changes'
            )}
          </Button>
        </div>
      </form>
      {shouldShowOutput ? (
        <Fragment>
          <Divider />
          <Output errors={errors} actions={actions} warnings={warnings} />
        </Fragment>
      ) : null}
    </Fragment>
  );
}

export default StartSyncJobTab;
