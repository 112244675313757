import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';
import {Modal, TextField, Button} from '@material-ui/core';

import resourceTypes from 'api/resourceTypes';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2, 4, 3),
    backgroundColor: 'white',
    border: '2px solid white',
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    outline: 'none',
  },
  inputField: {
    marginBottom: '1rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  button: {
    margin: '0 1rem',
    padding: '0.5rem 3rem',
    textTransform: 'none',
  },
  submitButton: {
    backgroundColor: '#4096b4',
    color: 'white',
    '&:hover': {
      backgroundColor: '#1f4958',
    },
  },
  cancelButton: {
    backgroundColor: 'rgb(10,30,50, 0.2)',
    '&:hover': {
      backgroundColor: 'rgb(111,176,199,0.2)',
    },
  },
}));

export default function ResourceTypeManagementModal(props) {
  const {title, isOpen, handleClose, resourceType, refresh} = props;
  const classes = useStyles();
  const [englishName, setEnglishName] = useState('');
  const [frenchName, setFrenchName] = useState('');

  // note: French name isn't in the GET api, so it can't be implemented yet
  useEffect(() => {
    if (resourceType) {
      setEnglishName(resourceType.title);
    } else {
      setEnglishName('');
    }
  }, [resourceType, isOpen]);

  function handleSubmit() {
    if (title === 'Add a Resource Type') {
      resourceTypes.addResourceType({display_name: {en: englishName, fr: frenchName}});
    } else {
      resourceTypes.editResourceType(resourceType.id, {display_name: {en: englishName, fr: frenchName}});
    }
    refresh();
    handleClose();
  }

  function handleCancel() {
    handleClose();
  }

  function handleChangeEnglish(event) {
    setEnglishName(event.target.value);
  }

  function handleChangeFrench(event) {
    setFrenchName(event.target.value);
  }

  return (
    <Modal open={isOpen} onClose={handleClose} data-testid="modal">
      <div className={classes.modal}>
        <h2>{title}</h2>
        <TextField
          onChange={handleChangeEnglish}
          value={englishName}
          label="English Name"
          variant="outlined"
          className={classes.inputField}
        />
        <TextField onChange={handleChangeFrench} value={frenchName} label="Nom Français" variant="outlined" />
        <div className={classes.buttonContainer}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            disableElevation
            className={`${classes.button} ${classes.submitButton}`}
          >
            Submit
          </Button>
          <Button
            onClick={handleCancel}
            variant="contained"
            disableElevation
            className={`${classes.button} ${classes.cancelButton}`}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}

ResourceTypeManagementModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  resourceType: PropTypes.object,
  refresh: PropTypes.func.isRequired,
};
