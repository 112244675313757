import fetch from 'api/fetch';

const regions = {
  regionManagement: async function() {
    const response = await fetch(process.env.REACT_APP_DIALOG_BASE_URL, '/regions');
    return response.data.data.regions;
  },
};

export default regions;
