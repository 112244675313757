import ContentSyncLink from 'components/ContentSyncPreview/ContentSyncLink';
import {convertISODateStringToLongDayFormat} from 'lib/timeHelpers';
import React from 'react';

export const allConfig = [
  {
    title: 'Sync Type',
    field: 'access_type',
    classKey: 'smallColumn',
  },
  {
    title: 'App Key',
    field: 'app_key',
    classKey: 'smallColumn',
  },
  {
    title: 'Time Synced',
    field: 'time_synced',
    classKey: 'smallColumn',
    formatter: convertISODateStringToLongDayFormat,
  },
  {
    title: 'Accessor ID',
    field: 'accessor_id',
    classKey: 'smallColumn',
  },
  {
    title: 'Owner ID',
    field: 'owner_id',
    classKey: 'smallColumn',
  },
  {
    title: 'Accessor Display Name',
    field: 'accessor_display_name',
    classKey: 'smallColumn',
  },
  {
    title: 'Owner Display Name',
    field: 'owner_display_name',
    classKey: 'smallColumn',
  },
  {
    title: 'Original File Structure',
    field: 'original_filename',
    classKey: 'mediumColumn',
  },
  {
    title: 'New File Name',
    field: 'bucket_key',
    classKey: 'mediumColumn',
  },
  {
    title: 'Download Link',
    field: 'id',
    formatter: (id, additionalData) => <ContentSyncLink id={id} additionalData={additionalData} />,
    classKey: 'smallColumn',
  },
];

export const sharedConfig = [
  {
    title: 'App Key',
    field: 'app_key',
    classKey: 'smallColumn',
  },
  {
    title: 'Time Synced',
    field: 'time_synced',
    classKey: 'mediumColumn',
    formatter: convertISODateStringToLongDayFormat,
  },
  {
    title: 'User ID - Shared With',
    field: 'accessor_id',
    classKey: 'smallColumn',
  },
  {
    title: 'User ID - Owner',
    field: 'owner_id',
    classKey: 'smallColumn',
  },
  {
    title: 'User Display Name - Shared With',
    field: 'accessor_display_name',
    classKey: 'smallColumn',
  },
  {
    title: 'User Display Name - Owner',
    field: 'owner_display_name',
    classKey: 'smallColumn',
  },
  {
    title: 'Original File Structure',
    field: 'original_filename',
    classKey: 'mediumColumn',
  },
  {
    title: 'New File Name',
    field: 'bucket_key',
    classKey: 'mediumColumn',
  },
  {
    title: 'Download Link',
    field: 'id',
    formatter: (id, additionalData) => <ContentSyncLink id={id} additionalData={additionalData} />,
    classKey: 'smallColumn',
  },
];

export const userConfig = [
  {
    title: 'App Key',
    field: 'app_key',
    classKey: 'smallColumn',
  },
  {
    title: 'Time Synced',
    field: 'time_synced',
    classKey: 'mediumColumn',
    formatter: convertISODateStringToLongDayFormat,
  },
  {
    title: 'User ID',
    field: 'owner_id',
    classKey: 'smallColumn',
  },
  {
    title: 'User Display Name',
    field: 'owner_display_name',
    classKey: 'smallColumn',
  },
  {
    title: 'Original File Structure',
    field: 'original_filename',
    classKey: 'mediumColumn',
  },
  {
    title: 'New File Name',
    field: 'bucket_key',
    classKey: 'mediumColumn',
  },
  {
    title: 'Download Link',
    field: 'id',
    formatter: (id, additionalData) => <ContentSyncLink id={id} additionalData={additionalData} />,
    classKey: 'smallColumn',
  },
];

export const groupConfig = [
  {
    title: 'App Key',
    field: 'app_key',
    classKey: 'smallColumn',
  },
  {
    title: 'Time Synced',
    field: 'time_synced',
    classKey: 'mediumColumn',
    formatter: convertISODateStringToLongDayFormat,
  },
  {
    title: 'Group ID',
    field: 'owner_id',
    classKey: 'smallColumn',
  },
  {
    title: 'Group Display Name',
    field: 'owner_display_name',
    classKey: 'smallColumn',
  },
  {
    title: 'Original File Structure',
    field: 'original_filename',
    classKey: 'mediumColumn',
  },
  {
    title: 'New File Name',
    field: 'bucket_key',
    classKey: 'mediumColumn',
  },
  {
    title: 'Download Link',
    field: 'id',
    formatter: (id, additionalData) => <ContentSyncLink id={id} additionalData={additionalData} />,
    classKey: 'smallColumn',
  },
];
