import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {Typography} from '@material-ui/core';

import SheetStatusChip from 'components/DocumentManagement/SheetStatusChip';
import sheetModel from 'components/DocumentManagement/SheetModel';
import {DocumentManagementSheetStatus} from 'api/documentManagement';

function SheetSummary({sheet}) {
  return (
    <Card>
      <CardContent>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            {sheet.appKeys.length ? (
              <Typography gutterBottom variant="h5" component="h2">
                Apps: {sheet.formattedAppKeys}
              </Typography>
            ) : null}
            {sheet.authorNames.length ? (
              <Typography gutterBottom variant="h5" component="h2">
                Authors: {sheet.formattedAuthorNames}
              </Typography>
            ) : null}
            <Typography variant="body2" color="textSecondary" component="p">
              Last updated on <b>{sheet.formattedUpdatedAt}</b>
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Created by <b>{sheet.createdBy}</b> on <b>{sheet.formattedCreatedAt}</b>
            </Typography>
          </Grid>
          <Grid item>
            <SheetStatusChip sheet={sheet}></SheetStatusChip>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        {sheet.status !== DocumentManagementSheetStatus.GENERATING && (
          <Button size="small" color="secondary" href={sheet.sheetURL} target="_blank" rel="noreferrer">
            View Sheet
          </Button>
        )}
      </CardActions>
    </Card>
  );
}

SheetSummary.propTypes = {
  sheet: PropTypes.shape(sheetModel).isRequired,
};

export default SheetSummary;
