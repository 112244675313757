import React, {useState, useEffect, memo, useCallback, useRef} from 'react';
import {makeStyles, Typography, Grid, Button} from '@material-ui/core';
import {Alert} from '@material-ui/lab';

import auth from 'api/auth';
import documentManagement, {DocumentManagementSheetStatus} from 'api/documentManagement';
import VirtualizedList from 'components/Common/VirtualizedList';
import DocumentManagementListItem from 'components/DocumentManagement/DocumentManagementListItem';
import SheetDialog from 'components/DocumentManagement/SheetDialog';
import {LIST_ROW_HEIGHT, COMPONENT_STATUS, DOCUMENT_MANAGEMENT_SHEETS_POLLING_INTERVAL} from 'lib/constants';
import {convertISODateStringToHuman} from 'lib/timeHelpers';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: '30px 50px',
    flex: 1,
  },

  tableHeader: {
    paddingBottom: '5px',
  },

  content: {
    flex: 1,
    padding: '10px 0px',
    height: '100%',
  },

  button: {
    fontSize: '12px',
    width: '100px',
    height: `calc(${LIST_ROW_HEIGHT}px - 15px)`,
    marginRight: 15,
  },

  applyChanges: {
    width: '100px',
    background: theme.palette.warning.main,
    '&:hover': {
      background: theme.palette.warning.dark,
    },
  },
  subTitle: {
    color: '#757575',
    fontStyle: 'italic',
  },
  idColumn: {
    width: '60px',
    color: '#757575',
    fontSize: '8pt',
    fontStyle: 'italic',
  },
  sheetColumn: {
    width: '100%',
    maxWidth: '200px',
  },
  statusColumn: {
    width: '100%',
    maxWidth: '100px',
  },
  appKeysColumn: {
    width: '100%',
    maxWidth: '100px',
  },
  authorsColumn: {
    width: '100%',
    maxWidth: '100px',
  },
  createdByColumn: {
    width: '100%',
    maxWidth: '250px',
  },
  createdAtColumn: {
    width: '100%',
    maxWidth: '250px',
  },
  updatedAtColumn: {
    width: '100%',
    maxWidth: '250px',
  },
  listItem: {
    cursor: 'pointer',
  },
  fetchInfo: {
    paddingBottom: '10px',
    marginBottom: '10px',
    borderBottom: '1px solid #e0e0e0',
  },
  reloadLink: {
    color: 'rgb(0, 0, 238)',
    textDecoration: 'underline',
    textTransform: 'none',
    minWidth: '32px',
    padding: '0px',
  },
}));

const formatMilliseconds = (ms) => {
  const minutes = Math.floor(ms / 60000);
  const seconds = ((ms % 60000) / 1000).toFixed(0);
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
};

export default function DocumentManagement() {
  const [state, setState] = useState({status: COMPONENT_STATUS.LOADING, sheets: [], error: ''});
  const [activeSheet, setActiveSheet] = useState(null);
  const [reloadSheets, setReloadSheets] = useState(false);
  const [userEmail] = useState(() => auth.getUserEmail());
  const {status, sheets, error} = state;
  const pollingInterval = useRef();

  const classes = useStyles();
  const MemoizedDocumentManagementListItem = memo(DocumentManagementListItem);
  const [lastFetchDateTime, setLastFetchDateTime] = useState(null);

  const formattedPollingInterval = formatMilliseconds(DOCUMENT_MANAGEMENT_SHEETS_POLLING_INTERVAL);

  useEffect(() => {
    async function fetchSheets() {
      try {
        setState({status: COMPONENT_STATUS.LOADING, sheets: [], error: ''});
        setLastFetchDateTime(new Date());
        const sheets = await documentManagement.getAllSheets();
        setState({status: COMPONENT_STATUS.SUCCESS, sheets});
      } catch (error) {
        setState({status: COMPONENT_STATUS.ERROR, error: error.message});
      }
    }

    // Do an initial fetch before starting the polling interval
    fetchSheets();
    pollingInterval.current = setInterval(fetchSheets, DOCUMENT_MANAGEMENT_SHEETS_POLLING_INTERVAL);

    return () => {
      clearInterval(pollingInterval.current);
    };
  }, [reloadSheets]);

  const onPreviewClick = useCallback(
      async (sheet) => {
        await documentManagement.previewChanges(sheet.sheetKey);
        setActiveSheet(sheet);
        setReloadSheets(!reloadSheets);
      },
      [reloadSheets],
  );

  const onApplyChangesClick = useCallback(
      async (sheet) => {
        if (
          sheet.createdBy === userEmail ||
        window.confirm('You do not own this sheet. Are you sure you want to apply changes?')
        ) {
          await documentManagement.importChanges(sheet.sheetKey);
          setActiveSheet(sheet);
          setReloadSheets(!reloadSheets);
        }
      },
      [reloadSheets, userEmail],
  );

  const onRowClick = useCallback(
      (sheet) => {
        setActiveSheet(sheet);
        setReloadSheets(!reloadSheets);
      },
      [reloadSheets],
  );

  const onSheetDialogClose = useCallback(() => {
    setActiveSheet(null);
    setReloadSheets(!reloadSheets);
  }, [reloadSheets]);

  const shouldEnableActions = useCallback((sheet) => {
    return sheet.status === DocumentManagementSheetStatus.ACTIVE;
  }, []);

  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h1">
        Document Management
      </Typography>
      <Typography variant="subtitle1" className={classes.subTitle}>
        Keep track of current Google Sheets
      </Typography>
      <div className={classes.content}>
        {activeSheet && <SheetDialog sheet={activeSheet} onClose={onSheetDialogClose} />}

        <div className={classes.fetchInfo}>
          <Alert severity="info">
            {lastFetchDateTime &&
              `The data is fetched approximately every ${formattedPollingInterval} minutes.
              Last fetched at: ${convertISODateStringToHuman(lastFetchDateTime)}. `}
            Click{' '}
            <Button className={classes.reloadLink} onClick={() => setReloadSheets(!reloadSheets)}>
              here
            </Button>{' '}
            to refresh now.
          </Alert>
        </div>

        {status === COMPONENT_STATUS.LOADING && <Alert severity="info">Loading...</Alert>}
        {status === COMPONENT_STATUS.ERROR && <Alert severity="error">{error}</Alert>}
        {status === COMPONENT_STATUS.SUCCESS && (
          <VirtualizedList
            items={sheets}
            rowHeight={LIST_ROW_HEIGHT}
            noDataContent="No sheets found"
            header={
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={1}
                className={classes.tableHeader}
                data-testid="sheets-list-header"
              >
                <Grid item className={classes.idColumn}>
                  ID
                </Grid>
                <Grid item container className={classes.sheetColumn}>
                  <Typography variant="body2">Sheet URL</Typography>
                </Grid>
                <Grid item container className={classes.statusColumn}>
                  <Typography variant="body2">Status</Typography>
                </Grid>
                <Grid item className={classes.appKeysColumn}>
                  <Typography variant="body2">App Keys</Typography>
                </Grid>
                <Grid item className={classes.authorsColumn}>
                  <Typography variant="body2">Authors</Typography>
                </Grid>
                <Grid item className={classes.createdByColumn}>
                  <Typography variant="body2">Created By</Typography>
                </Grid>
                <Grid item className={classes.updatedAtColumn}>
                  <Typography variant="body2">Updated At</Typography>
                </Grid>
              </Grid>
            }
            rowTemplate={({index, item, style, key}) => {
              return (
                <MemoizedDocumentManagementListItem
                  key={key}
                  style={style}
                  classes={classes}
                  index={index}
                  sheet={item}
                  onRowClick={onRowClick}
                  shouldEnableActions={shouldEnableActions}
                  onPreviewClick={onPreviewClick}
                  onApplyChangesClick={onApplyChangesClick}
                />
              );
            }}
            showSpinner={status === COMPONENT_STATUS.LOADING}
          />
        )}
      </div>
    </div>
  );
}
