import fetch from 'api/fetch';
import {buildQueryString, convertObjectKeysToCamelCase} from 'api/utils';
import {LABEL_SYNC_JOBS_ENDPOINT} from 'lib/constants';

/**
 * The status of a Label Sync Job
 * @readonly
 * @enum {string}
 */
const LabelSyncJobStatus = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  EXCEPTION: 'EXCEPTION',
};

/** Interface to backend Label Sync APIs */
const labelSync = {
  /**
   * @typedef {Object} LabelSyncDiff
   * @property {String[]} actions  A list of messages describing the change set for the job
   * @property {String[]} warnings A list of warning messages
   * @property {String[]} errors   A list of error messages
   */

  /**
   * @typedef {Object} LabelSyncJob
   * @property {Number}              id          The job id
   * @property {String}              sheetName   The name of a Google Sheet containing the label data for the job
   * @property {Number}              dryRun      If true, the diff will be calculated but not executed
   * @property {LabelSyncJobStatus}  status      The current status of the job
   * @property {LabelSyncDiff}       diff        A summary of the changes made for the job
   * @property {String}              startedAt   When the job started
   * @property {String}              completedAt When the job finished if applicable
   */

  /**
   * @typedef {Object} LabelSyncJobPagedList
   * @property {LabelSyncJob[]} items   The list of jobs on this page
   * @property {Bool}           hasMore Used to indicate if there more items available past this page
   * @property {Number}         offset  The offset for the next page if relevant
   */

  /**
   * Create a Label Sync job for the given sheet
   * @param   {String}       sheetName The name of a Google Sheet containing the label data
   * @param   {Bool}         dryRun    If true, the diff will be calculated but not executed
   * @returns {LabelSyncJob}           The new job
   */
  createJob: async function(sheetName, dryRun = true) {
    if (!sheetName) {
      throw new Error('sheetName is required');
    }

    const response = await fetch(process.env.REACT_APP_DIALOG_BASE_URL, LABEL_SYNC_JOBS_ENDPOINT, 'POST', {
      dry_run: dryRun,
      sheet_name: sheetName,
    });
    return convertObjectKeysToCamelCase(response.data);
  },

  /**
   * Get the state of a job
   * @param   {number}       jobId The ID of the job
   * @returns {LabelSyncJob}       The job
   */
  getJob: async function(jobId) {
    if (!jobId) {
      throw new Error('jobId is required');
    }

    const response = await fetch(process.env.REACT_APP_DIALOG_BASE_URL, `${LABEL_SYNC_JOBS_ENDPOINT}/${jobId}`, 'GET');
    return convertObjectKeysToCamelCase(response.data);
  },

  /**
   * Get a list of the most recent jobs
   * @param   {Number}                offset The number of jobs to skip before starting to collect the result set
   * @param   {Number}                limit  The maximum number of jobs to return
   * @returns {LabelSyncJobPagedList}        The items fetched along with information on fetching the next page if
   * relevant
   */
  getJobList: async function(offset, limit) {
    const queryParams = {
      offset: offset,
      limit: limit,
    };

    const response = await fetch(
        process.env.REACT_APP_DIALOG_BASE_URL,
        `${LABEL_SYNC_JOBS_ENDPOINT}${buildQueryString(queryParams)}`,
        'GET',
    );
    return convertObjectKeysToCamelCase(response.data);
  },
};

export {LabelSyncJobStatus};
export default labelSync;
