import {React, useEffect, useState, useMemo, useCallback} from 'react';
import {makeStyles, Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import app from 'api/app.js';
import resourceTypes from 'api/resourceTypes';
import AppTableHead from 'components/AppManagement/AppTableHead.jsx';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import debounce from 'lodash.debounce';
import {TEXT_INPUT_ONCHANGE_DEBOUNCE_DELAY} from 'lib/constants';
import PropTypes from 'prop-types';
import escapeRegExp from 'lodash.escaperegexp';
import {COMPONENT_STATUS} from 'lib/constants';
import {Alert} from '@material-ui/lab';
import AppManagementModal from 'components/AppManagement/AppManagementModal.jsx';
import AppManagementEditModal from 'components/AppManagement/AppManagementEditModal.jsx';
import Add from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    height: '100%',
    padding: '30px 50px',
    display: 'flex',
    flexDirection: 'column',
  },
  subTitle: {
    color: '#757575',
    fontStyle: 'italic',
    marginBottom: '20px',
  },
  table: {
    minWidth: 650,
  },
  addButton: {
    backgroundColor: '#4095b3',
    width: '150px',
    borderRadius: '4px',
    color: 'white',
    height: '40px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#5eaac5',
      boxShadow: 'none',
    },
    textTransform: 'none',
  },
  editButton: {
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: '0.6',
    },
    width: '48px',
    height: '48px',
    padding: '0',
  },
}));

// createData(id, appID, desc, lang, drt, prt, lob) takes
//   unformatted data fetched by the API and returns a
//   formatted data object.
function createData(id, appId, desc, lang, drt, prt, lob, subType) {
  const langPretty = lang.join(' | ').toUpperCase();
  const drtPretty = drt.join(', ');
  const prtPretty = prt.join(', ');
  const lobPretty = lob.join(', ');
  return {
    id,
    appId,
    desc,
    langPretty,
    drtPretty,
    prtPretty,
    lobPretty,
    subType,
  };
}

// descendingComparator(a, b, orderBy) compares attribute
//   orderBy of two objects a and b.
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// getComparator(order, orderBy) changes the sign of
//    the comparison operator descendingComparator depending
//    on which order the table is sorted by.
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function AppManagement({debounceDelay = TEXT_INPUT_ONCHANGE_DEBOUNCE_DELAY}) {
  const classes = useStyles();

  // State management for Create App Modal
  const [openCreate, setOpenCreate] = useState(false);
  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);

  // State management for Edit App Modal
  const [openEdit, setOpenEdit] = useState(false);
  const [editData, setEditData] = useState({subType: null, langs: null});
  const handleOpenEdit = (rowId) => {
    // To find which row is being edited, only looks in
    // visibleRows to save time.
    const chosenRow = visibleRows.find((row) => row.appId === rowId);

    // Figure out prepopulated values
    const langList = chosenRow.langPretty.split(' | ');
    const langBoolList = [false, false];
    if (langList.includes('EN')) langBoolList[0] = true;
    if (langList.includes('FR')) langBoolList[1] = true;

    // Save prepopulated values to state and open modal
    setEditData({
      appId: chosenRow.appId,
      drt: chosenRow.drtPretty.split(', '),
      prt: chosenRow.prtPretty.split(', '),
      subType: chosenRow.subType,
      langs: langBoolList,
      resources: resourceData,
    });
    setOpenEdit(true);
  };
  const handleCloseEdit = () => setOpenEdit(false);

  const [state, setState] = useState({status: COMPONENT_STATUS.LOADING, error: ''});
  const {status, error} = state;
  const [appData, setAppData] = useState([]);
  const [resourceData, setResourceData] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterValue, setFilterValue] = useState('');

  async function getAppData() {
    try {
      const response = await app.getSageApps();
      return response;
    } catch (error) {
      setState({status: COMPONENT_STATUS.ERROR, error: error.message});
    }
  }

  async function getResourceTypeData() {
    try {
      const response = await resourceTypes.getResourceTypes();
      return response;
    } catch (error) {
      setState({status: COMPONENT_STATUS.ERROR, error: error.message});
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // getTitles(resourceTypesData, ids) creates a list resource type titles
  // that correspond to the given list of resource type ids
  const getTitles = (resourceTypesData, ids) => {
    const titles = ids.map((id) => {
      for (let i = 0; i < resourceTypesData.length; i++) {
        if (resourceTypesData[i].id === id) {
          return resourceTypesData[i].title;
        }
      }
      return 'error';
    });
    return titles;
  };

  const handleGetData = useCallback(() => {
    let resourceTypesData = [];
    setState({status: COMPONENT_STATUS.LOADING, error: ''});
    getResourceTypeData().then((result) => {
      try {
        resourceTypesData = result;
        setResourceData(resourceTypesData);
        getAppData().then((result) => {
          const data = [];
          setState({status: COMPONENT_STATUS.SUCCESS, error: ''});
          try {
            result.forEach((app) => {
              const row = createData(
                  app.id,
                  app.app_id,
                  app.description,
                  app.languages,
                  getTitles(resourceTypesData, app.portal_config.downloadable_resource_types),
                  getTitles(resourceTypesData, app.portal_config.printable_resource_types),
                  app.lines_of_business,
                  app.app_sub_type,
              );
              data.push(row);
            });
          } catch (error) {
            setState({status: COMPONENT_STATUS.ERROR, error: 'Something went wrong'});
          }
          setAppData(data);
        });
      } catch (error) {
        setState({status: COMPONENT_STATUS.ERROR, error: 'Something went wrong'});
      }
    });
  }, []);

  const handleReloadData = () => {
    setState({status: COMPONENT_STATUS.LOADING, error: ''});
    handleGetData();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // visibleRowCalculation(rows, order, orderBy, page, rowsPerPage) returns
  //   only the rows that are on the current page of the table.
  function visibleRowCalculation(rows, order, orderBy, page, rowsPerPage) {
    const sorted = rows
        .slice()
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    return sorted;
  }

  // filterCalculation(data, filterValue) filters the data based on
  //   the search term. Returns a filtered copy of the data.
  function filterCalculation(data, filterValue) {
    if (!filterValue) {
      return data;
    }
    const filterExpressionString = filterValue
        .replace(/\s+/g, ' ')
        .split(' ')
        .map((part) => escapeRegExp(part))
        .join('.*');

    const filterExpression = new RegExp(filterExpressionString, 'gi');
    return data.filter(
        (app) =>
          filterExpression.test(app.id) ||
        filterExpression.test(app.appId) ||
        filterExpression.test(app.desc) ||
        filterExpression.test(app.langPretty) ||
        filterExpression.test(app.drtPretty) ||
        filterExpression.test(app.prtPretty) ||
        filterExpression.test(app.lobPretty),
    );
  }
  // filteredRows is all the rows that fit the search term (no search term = all rows)
  const filteredRows = useMemo(() => filterCalculation(appData, filterValue), [appData, filterValue]);

  // visibleRows is all the rows that are on the current page of the table
  const visibleRows = useMemo(
      () => visibleRowCalculation(filteredRows, order, orderBy, page, rowsPerPage),
      [order, orderBy, page, rowsPerPage, filteredRows],
  );

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  const handleFilterValueChange = useMemo(
      () =>
        debounce((event) => {
          setPage(0);
          setFilterValue(event.target.value);
        }, debounceDelay),
      [debounceDelay, setFilterValue],
  );

  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h1">
        App Management
      </Typography>
      <Typography variant="subtitle1" className={classes.subTitle}>
        Manage your Applications
      </Typography>
      <Grid item container direction="row" alignItems="center" spacing={1} style={{marginBottom: '20px'}}>
        <Grid item style={{flexGrow: '1'}}>
          <TextField
            label="Search..."
            variant="outlined"
            size="small"
            length="200px"
            className={classes.filterInput}
            onChange={handleFilterValueChange}
            data-testid="document-viewer-list-filter-input"
          />
        </Grid>
        <Grid>
          <Button variant="contained" startIcon={<Add />} className={classes.addButton} onClick={handleOpenCreate}>
            Create App
          </Button>
          {openCreate && (
            <AppManagementModal open={openCreate} onClose={handleCloseCreate} onSubmit={handleReloadData} />
          )}
        </Grid>
        {openEdit && (
          <AppManagementEditModal
            open={openEdit}
            onClose={handleCloseEdit}
            onSubmit={handleReloadData}
            data={editData}
          />
        )}
      </Grid>

      {status === COMPONENT_STATUS.LOADING && <Alert severity="info">Loading...</Alert>}
      {status === COMPONENT_STATUS.ERROR && <Alert severity="error">{error}</Alert>}
      {status === COMPONENT_STATUS.SUCCESS && (
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <AppTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {visibleRows.map((row, index) => {
                return (
                  <TableRow tabIndex={-1} key={row.name}>
                    <TableCell align="right">{row.id}</TableCell>
                    <TableCell align="left" style={{fontWeight: 'bold'}}>
                      {row.appId}
                    </TableCell>
                    <TableCell align="left">{row.desc}</TableCell>
                    <TableCell align="center">{row.langPretty}</TableCell>
                    <TableCell align="center">{row.drtPretty}</TableCell>
                    <TableCell align="center">{row.prtPretty}</TableCell>
                    <TableCell align="center">{row.lobPretty}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        aria-label="edit"
                        className={classes.editButton}
                        onClick={() => handleOpenEdit(row.appId)}
                      >
                        <Edit style={{color: '#4095b3'}} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

AppManagement.propTypes = {
  debounceDelay: PropTypes.number,
};
