import React, {useState, useEffect, memo} from 'react';

import {makeStyles, alpha} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {Alert, AlertTitle} from '@material-ui/lab';

import SyncHistoryListItem from 'components/LabelSync/SyncHistoryListItem';
import SyncHistoryJobDisplayDialog from 'components/LabelSync/SyncHistoryJobDisplayDialog';

import VirtualizedList from 'components/Common/VirtualizedList';
import labelSync from 'api/labelSync';
import {getErrorMessageString} from 'lib/helper';
import {LIST_ROW_HEIGHT} from 'lib/constants';

export const MAX_JOBS_TO_SHOW = 100;

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    padding: '10px 0px',
    height: '100%',
  },
  tableHeader: {
    paddingBottom: '5px',
  },
  tableRow: {
    '&:hover': {
      background: alpha(theme.palette.primary.main, 0.2),
    },
  },
  tableRowOdd: {
    background: alpha(theme.palette.primary.main, 0.05),
  },
  idColumn: {
    width: '60px',
    color: '#757575',
    fontSize: '8pt',
    fontStyle: 'italic',
  },
  statusColumn: {
    width: '100%',
    maxWidth: '80px',
  },
  sheetNameColumn: {
    width: '100%',
    maxWidth: '300px',
  },
  startedAtColumn: {
    width: '100%',
    maxWidth: '250px',
  },
  finishedAtColumn: {
    width: '100%',
    maxWidth: '250px',
  },
  dryRunColumn: {
    width: '100%',
    maxWidth: '80px',
  },
}));

function SyncJobHistoryTab() {
  const classes = useStyles();

  const [syncHistory, setSyncHistory] = useState(null);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [historyLoadingError, setHistoryLoadingError] = useState(null);
  const [displayedSyncJob, setDisplayedSyncJob] = useState(null);

  const MemoizedSyncHistoryListItem = memo(SyncHistoryListItem);

  useEffect(() => {
    setIsLoadingHistory(true);
    let abort = false;

    labelSync
        .getJobList(0, MAX_JOBS_TO_SHOW)
        .then(({items: jobs}) => {
          if (abort) {
            return;
          }

          setSyncHistory(jobs);
          setIsLoadingHistory(false);
        })
        .catch((err) => {
          setHistoryLoadingError(getErrorMessageString(err));
          setIsLoadingHistory(false);
        });

    return () => {
      abort = true;
      setIsLoadingHistory(false);
      setSyncHistory([]);
    };
  }, []);

  const handleClose = () => {
    setDisplayedSyncJob(null);
  };

  return (
    <div className={classes.root}>
      {historyLoadingError && (
        <Alert severity="error" className={classes.loadingError} data-testid={`error:${historyLoadingError}`}>
          <AlertTitle>Error Loading Sync Job History</AlertTitle>
          {historyLoadingError}
        </Alert>
      )}
      <SyncHistoryJobDisplayDialog syncJob={displayedSyncJob} handleClose={handleClose} />
      <VirtualizedList
        items={syncHistory}
        rowHeight={LIST_ROW_HEIGHT}
        header={
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={1}
            className={classes.tableHeader}
            data-testid="job-history-list-header"
          >
            <Grid item className={classes.idColumn}>
              ID
            </Grid>
            <Grid item container className={classes.statusColumn}>
              <Typography variant="body2">Status</Typography>
            </Grid>
            <Grid item container className={classes.dryRunColumn}>
              <Typography variant="body2">Run Type</Typography>
            </Grid>
            <Grid item className={classes.sheetNameColumn}>
              <Typography variant="body2">Sheet Name</Typography>
            </Grid>
            <Grid item className={classes.startedAtColumn}>
              <Typography variant="body2">Started At</Typography>
            </Grid>
            <Grid item className={classes.finishedAtColumn}>
              <Typography variant="body2">Finished At</Typography>
            </Grid>
          </Grid>
        }
        rowTemplate={({index, item, style, key}) => {
          return (
            <MemoizedSyncHistoryListItem
              key={key}
              style={style}
              classes={classes}
              index={index}
              syncJob={item}
              setDisplayedSyncJob={setDisplayedSyncJob}
            />
          );
        }}
        showSpinner={isLoadingHistory}
      />
    </div>
  );
}

export default SyncJobHistoryTab;
