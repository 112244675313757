import camelCase from 'lodash.camelcase';
import isPlainObject from 'lodash.isplainobject';

/**
 * Build a query string from a dictionary of parameters
 * @param {Object} params
 * @returns {String}
 */
export function buildQueryString(params) {
  const queryString = Object.entries(params)
      .filter(([key, value]) => value)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');

  if (queryString) {
    return '?' + queryString;
  } else {
    return '';
  }
}

/**
 * Convert any Object keys of the target from arbitrary casing to camel case
 * @template T
 * @param {T} target The target for conversion
 * @returns {T} A copy of the target with camel case keys where applicable
 */
export function convertObjectKeysToCamelCase(target) {
  if (Array.isArray(target)) {
    return target.map((value) => convertObjectKeysToCamelCase(value));
  }

  if (isPlainObject(target)) {
    return Object.entries(target).reduce((result, [key, value]) => {
      result[camelCase(key)] = convertObjectKeysToCamelCase(value);
      return result;
    }, {});
  }

  return target;
}
