import React from 'react';
import PropTypes from 'prop-types';
import {TextField, MenuItem} from '@material-ui/core';

export default function Dropdown({value, label, id, handleChange, helperText, isError, options}) {
  return (
    <TextField
      id={id}
      select
      label={label}
      value={value}
      onChange={handleChange}
      error={isError}
      helperText={helperText}
      >
      {options.map((option) => (
        <MenuItem key={option.key} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

Dropdown.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  isError: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
  ).isRequired,
};
