import {React, useState} from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import {makeStyles, Typography} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import app from 'api/app';
import {PropTypes} from 'prop-types';
import ErrorOutline from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 'auto',
    backgroundColor: 'white',
    padding: '30px',
    borderRadius: '15px',
  },
  bottomButtons: {
    height: 50,
    width: 150,
  },
  title: {
    marginBottom: 16,
  },
  fieldHeaders: {
    marginBottom: 8,
  },
  errorBar: {
    backgroundColor: '#FFE6E5',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    marginTop: 32,
  },
  checkList: {
    width: '100%',
    height: 160,
    bgcolor: 'background.paper',
    overflow: 'auto',
    border: '2px #DEECF2 solid',
    borderRadius: 5,
    '&:hover': {
      borderColor: '#6fb3cb',
    },
  },
}));

function compareResources(a, b) {
  if (a.title < b.title) return -1;
  if (a.title > b.title) return 1;
  return 0;
}

export default function AppManagementEditModal(props) {
  props.data.resources.sort(compareResources);

  const classes = useStyles();
  const [hasLanguages, setHasLanguages] = useState(props.data.langs);
  const [appSubType, setAppSubType] = useState(props.data.subType);
  const [selectedDownloadables, setSelectedDownloadables] = useState(props.data.drt);
  const [selectedPrintables, setSelectedPrintables] = useState(props.data.prt);
  const [alertIfEmpty, setAlertIfEmpty] = useState(false);
  const [showAPIError, setShowAPIError] = useState(false);

  function handleResourceCheck(title, checked, isDRT) {
    let state;
    let setter;

    if (isDRT) {
      state = selectedDownloadables;
      setter = setSelectedDownloadables;
    } else {
      state = selectedPrintables;
      setter = setSelectedPrintables;
    }

    if (checked) {
      const newState = state.filter(function(item) {
        return item !== title;
      });
      setter(newState);
    } else {
      const newState = state.concat([title]);
      setter(newState);
    }
  }
  async function handleSubmit() {
    if (hasLanguages[0] === false && hasLanguages[1] === false) {
      setAlertIfEmpty(true);
      return;
    }
    const langs = [];
    if (hasLanguages[0] === true) {
      langs.push('en');
    }
    if (hasLanguages[1] === true) {
      langs.push('fr');
    }
    const selectedDRTsCopy = selectedDownloadables.slice(0).filter((drt) => drt !== '');
    const selectedPRTsCopy = selectedPrintables.slice(0).filter((prt) => prt !== '');

    const selectedDRTids = selectedDRTsCopy.map(function(label) {
      const hi = props.data.resources.find((resource) => resource.title === label);
      return hi.id;
    });
    const selectedPRTids = selectedPRTsCopy.map(function(label) {
      const hi = props.data.resources.find((resource) => resource.title === label);
      return hi.id;
    });
    try {
      const response = await app.editSageApp(props.data.appId, langs, appSubType, selectedDRTids, selectedPRTids);
      if (response.status === 200) {
        props.onSubmit();
        props.onClose();
      } else {
        // On API Error
        setShowAPIError(true);
      }
    } catch (error) {
      // This would technically be a network error, but displays same message
      setShowAPIError(true);
    }
  }

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box className={classes.modal}>
        <Typography variant="h4" component="h1" className={classes.title}>
          Editing <i>{props.data.appId}</i>
        </Typography>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div>
            <Typography variant="h6" className={classes.fieldHeaders}>
              Languages
            </Typography>
            <FormControlLabel
              value="top"
              control={
                <Checkbox
                  style={{color: '#4095b3'}}
                  checked={hasLanguages[0]}
                  onChange={(event) => {
                    setHasLanguages([event.target.checked, hasLanguages[1]]);
                  }}
                />
              }
              label="English"
              labelPlacement="end"
            />
            <FormControlLabel
              value="top"
              control={
                <Checkbox
                  checked={hasLanguages[1]}
                  style={{color: '#4095b3'}}
                  onChange={(event) => {
                    setHasLanguages([hasLanguages[0], event.target.checked]);
                  }}
                />
              }
              label="French"
              labelPlacement="end"
            />
            {alertIfEmpty && !hasLanguages.includes(true) && (
              <FormHelperText style={{color: '#f44336'}}>Please select at least one!</FormHelperText>
            )}
          </div>
          <div style={{marginLeft: 110, alignItems: 'right'}}>
            <Typography variant="h6" className={classes.fieldHeaders}>
              Sub-type
            </Typography>
            <FormControl fullWidth style={{marginTop: -16}}>
              <InputLabel id="subType"></InputLabel>
              <Select
                labelId="subType"
                id="subType"
                value={appSubType}
                onChange={(event) => {
                  setAppSubType(event.target.value);
                }}
                data-testid="subType"
              >
                <MenuItem value="broker">Broker</MenuItem>
                <MenuItem value="carrier">Carrier</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <Typography variant="h6" className={classes.fieldHeaders} style={{marginTop: 16, marginBottom: 0}}>
          Downloadables
        </Typography>
        <Box className={classes.checkList}>
          <FormGroup sx={{width: '100%', bgcolor: 'background.paper'}} style={{padding: 10}}>
            {props.data.resources.map((resource) => {
              const label = resource.title;
              const checked = selectedDownloadables.includes(resource.title);
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{color: '#4095b3'}}
                      checked={checked}
                      onChange={() => handleResourceCheck(label, checked, true)}
                    />
                  }
                  label={label}
                  key={resource.id}
                />
              );
            })}
          </FormGroup>
        </Box>
        <Typography variant="h6" className={classes.fieldHeaders} style={{marginTop: 16, marginBottom: 0}}>
          Printables
        </Typography>
        <Box className={classes.checkList}>
          <FormGroup sx={{width: '100%', bgcolor: 'background.paper'}} style={{padding: 10}}>
            {props.data.resources.map((resource) => {
              const label = resource.title;
              const checked = selectedPrintables.includes(resource.title);
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{color: '#4095b3'}}
                      checked={checked}
                      onChange={() => handleResourceCheck(label, checked, false)}
                    />
                  }
                  label={label}
                  key={resource.id}
                />
              );
            })}
          </FormGroup>
        </Box>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 16}}>
          <Button
            variant="contained"
            className={classes.bottomButtons}
            onClick={props.onClose}
            style={{backgroundColor: 'white', color: '#4095b3', border: '1px solid #4095b3'}}
          >
            Cancel
          </Button>
          <Box width="50px"></Box>
          <Button
            variant="contained"
            className={classes.bottomButtons}
            style={{backgroundColor: '#4095b3', color: 'white'}}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
        {showAPIError && (
          <div className={classes.errorBar}>
            <ErrorOutline color="error" style={{marginRight: '10'}} />
            <FormHelperText style={{color: 'black', fontSize: 13}}>
              Oops! An unknown error occured. Try again or come back later.
            </FormHelperText>
          </div>
        )}
      </Box>
    </Modal>
  );
}

AppManagementEditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};
