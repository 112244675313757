import fetch from 'api/fetch';

class User {
  /* Get user information by App and User ID */
  async getUserInfoByAppId(primaryUserId, appId) {
    try {
      // Expects that a valid token is already stored in the session.
      const encodedUserId = encodeURIComponent(primaryUserId);
      const response = fetch(
          process.env.REACT_APP_DIALOG_BASE_URL,
          `/${appId}/membership?user_id=${encodedUserId}`,
          'GET',
          null,
      );
      return response;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getUserMembershipsByAppKey(appId) {
    try {
      const response = fetch(process.env.REACT_APP_DIALOG_BASE_URL, `/${appId}/memberships`, 'GET', null);
      return response;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getPagedUserMembershipsByAppKey(appId, offset, pageSize) {
    try {
      const response = await fetch(
          process.env.REACT_APP_DIALOG_BASE_URL,
          `/${appId}/management/memberships?client=${appId}&offset=${offset}&page_size=${pageSize}`,
          'GET',
          null,
      );

      return Object.assign({...response.data}, {hasMore: response.data.has_more});
    } catch (error) {
      throw error.response.data;
    }
  }

  async deleteUser(appId, primaryUserId) {
    try {
      const response = fetch(
          process.env.REACT_APP_DIALOG_BASE_URL,
          `/${appId}/membership?user_id=${encodeURIComponent(primaryUserId)}`,
          'DELETE',
          null,
      );
      return response;
    } catch (error) {
      throw error.response.data;
    }
  }

  async deleteMembership(primaryUserId) {
    try {
      const response = fetch(process.env.REACT_APP_AUTH_BASE_URL, '/user', 'DELETE', {
        user_id: primaryUserId,
      });
      return response;
    } catch (error) {
      throw error.response.data;
    }
  }
}

export default new User();
