import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import {makeStyles, alpha} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    '&:hover': {
      background: alpha(theme.palette.primary.main, 0.2),
    },
  },
  tableRowOdd: {
    background: alpha(theme.palette.primary.main, 0.05),
  },
  xlTooltip: {
    maxWidth: '500px',
  },
}));

function ListItem({style, index, onClick, item, columns, className}) {
  const classes = useStyles();
  const isOddRow = index % 2 === 1;

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      spacing={1}
      className={clsx(classes.tableRow, className, isOddRow && classes.tableRowOdd)}
      style={style}
      data-testid={`list-row:${item.id}`}
      onClick={onClick}
      >
      {columns.map((column) => (
        <Grid item key={column.id} className={column.className}>
          {column.tooltip ? (
            <Tooltip placement="bottom-start" classes={{tooltip: classes.xlTooltip}} title={column.tooltip(item)}>
              {column.render(item)}
            </Tooltip>
          ) : (
            column.render(item)
          )}
        </Grid>
      ))}
    </Grid>
  );
}

ListItem.propTypes = {
  style: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default ListItem;
