import {createTheme} from '@material-ui/core/styles';

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#2E5BFF',
      resourceIdentity: '#FFB6C1',
      resource: '#90EE90',
    },
    secondary: {
      main: '#4096b4',
      hoverMain: '#5eaac5',
      light: '#deedf2',
      dark: '#1f4958',
    },
  },
});
