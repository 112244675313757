import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import ClearIcon from '@material-ui/icons/Clear';
import {
  ALL_OPTION,
  USER_SYNC_OPTION,
  GROUP_SYNC_OPTION,
  previewContentSyncMetadataExport,
  mapContentSyncMetadata,
  SHARED_SYNC_OPTION,
} from 'components/ContentSyncPreview/contentSyncUtils';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

import {
  makeStyles,
  alpha,
  FormControl,
  Button,
  CircularProgress,
  Typography,
  Backdrop,
  FormHelperText,
  IconButton,
  TablePagination,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import MultiSelectDropdown from 'components/MultiSelectDropdown';
import {useFormik} from 'formik';
import contentSync from 'api/contentSync';
import {isError} from 'lib/helper';
import app from 'api/app';
import moment from 'moment';
import ContentSyncPreviewPagedList from 'components/ContentSyncPreview/ContentSyncPreviewPagedList';
import {allConfig} from 'components/ContentSyncPreview/contentSyncTableConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    height: '100%',
    padding: '30px 50px',
    '& .MuiFormControl-root': {
      minWidth: '25%',
    },
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  form: {
    width: '100%',
    marginBottom: 25,
  },
  button: {
    padding: '13px 0px',
    textTransform: 'inherit',
    fontSize: 15,
    marginTop: 18,
    marginRight: 15,
    width: 250,
  },
  subTitle: {
    color: '#757575',
    fontStyle: 'italic',
  },
  buttonGroup: {
    '& .MuiToggleButton-root.Mui-selected': {
      background: alpha(theme.palette.primary.main, 0.5),
      color: 'black',
    },
    '& .MuiToggleButton-root': {
      color: 'black',
    },
  },
  optionBar: {
    paddingTop: '20px',
    paddingBottom: '20px',
  },
}));

const DEFAULT_PAGE_SIZE = 50;

const onSubmitValidator = ({appKey, startDate, endDate}) => {
  const errors = {};

  if (!appKey.length) {
    errors.appKey = 'Select an app key';
  }

  const endDateMoment = moment(endDate);
  const startDateMoment = moment(startDate);

  if (startDateMoment.diff(endDateMoment) > 0) {
    errors.endDate = 'End date must be after the start date!';
  }

  //allow for empty values (null) which is technically an invalid date
  if (endDate !== null && !endDateMoment.isValid()) {
    errors.endDate = 'End date is not valid!';
  }

  if (startDate !== null && !startDateMoment.isValid()) {
    errors.startDate = 'Start date is not valid!';
  }

  return errors;
};

// eslint-disable-next-line react/prop-types
const ClearableIconButton = ({onClick}) => {
  return (
    <IconButton onClick={onClick} edge="end">
      <ClearIcon />
    </IconButton>
  );
};

export default function ContentSyncImportPreview() {
  const [errors, setErrors] = useState([]);
  const [appKeys, setAppKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [contentSyncMetadata, setContentSyncMetadata] = useState({});
  const [totalResults, setTotalResults] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  useEffect(() => {
    let didCancel = false;
    const fetchAppKeys = async () => {
      setIsLoading(true);
      setErrors([]);

      try {
        const incomingAppKeys = await app.getSageApps();

        if (didCancel) {
          return;
        }

        if (!incomingAppKeys || incomingAppKeys.length === 0) {
          throw new Error('No app keys found');
        }

        setAppKeys(incomingAppKeys);
      } catch (error) {
        setErrors((prevErrors) => [error.message, ...prevErrors]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAppKeys();
    return () => {
      didCancel = true;
    };
  }, []);

  const formattedAppKeys = useMemo(() => {
    const formattedAppKeys = appKeys
        .map((app) => ({
          key: app.app_id,
          value: app.app_id,
          label: app.description,
        }))
        .sort((a, b) => a.value.localeCompare(b.value));

    formattedAppKeys.unshift({key: ALL_OPTION, value: ALL_OPTION, label: ALL_OPTION});
    return formattedAppKeys;
  }, [appKeys]);

  const onSubmitHandler = async (values, {setSubmitting}) => {
    try {
      setSubmitting(true);
      setErrors([]);
      setTotalResults(0);

      const {appKey, startDate, endDate} = values;
      const strippedAppKeys = appKey.includes(ALL_OPTION) ? [] : appKey;
      const response = await contentSync.previewContentSyncMetadata({
        appKeys: strippedAppKeys,
        startDate,
        endDate,
        pageSize,
        offset: page * pageSize,
      });

      if (response?.data?.records && response?.data?.total_count > 0) {
        const mappedContentSyncMetadata = mapContentSyncMetadata(response.data.records);
        setContentSyncMetadata(mappedContentSyncMetadata);
        setTotalResults(response.data.total_count);
      } else {
        setErrors((prevErrors) => ['No Results!', ...prevErrors]);
      }
    } catch (error) {
      setErrors((prevErrors) => [error.message, ...prevErrors]);
    } finally {
      setSubmitting(false);
    }
  };

  const classes = useStyles();
  const formik = useFormik({
    initialValues: {appKey: [], startDate: null, endDate: null},
    validate: onSubmitValidator,
    onSubmit: onSubmitHandler,
  });

  const handlePageChange = useCallback(
      (event, newPage) => {
        setPage(newPage);
        formik.submitForm();
      },
      [formik],
  );

  const handlePageSizeChange = useCallback(
      (event) => {
        setPage(0);
        setPageSize(parseInt(event.target.value));
        formik.submitForm();
      },
      [formik],
  );

  const handleReset = useCallback(() => {
    setContentSyncMetadata({});
    setErrors([]);
  }, []);

  const syncMap = useMemo(() => {
    return {
      [ALL_OPTION]: {
        data: contentSyncMetadata[USER_SYNC_OPTION] &&
          contentSyncMetadata[GROUP_SYNC_OPTION] &&
          contentSyncMetadata[SHARED_SYNC_OPTION] && [
          ...contentSyncMetadata[USER_SYNC_OPTION],
          ...contentSyncMetadata[GROUP_SYNC_OPTION],
          ...contentSyncMetadata[SHARED_SYNC_OPTION],
        ],
        tableTitle: 'All Sync Types',
        config: allConfig,
      },
    };
  }, [contentSyncMetadata]);

  const handleExport = useCallback(() => {
    const {appKeys, startDate, endDate} = formik.values;
    const retrievalIncrements = 2000;

    previewContentSyncMetadataExport({
      appKeys,
      startDate,
      endDate,
      pageSize: retrievalIncrements,
      mapContentSyncMetadata,
    });
  }, [formik.values]);

  const shouldShowOutput = useMemo(() => {
    return Object.keys(contentSyncMetadata).length > 0;
  }, [contentSyncMetadata]);

  if (isLoading) {
    return (
      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className={classes.root}>
        <Typography variant="h4" component="h1">
          Content Sync Import Preview
        </Typography>
        <Typography variant="subtitle1" className={classes.subTitle}>
          View documents that have been uploaded via content sync
        </Typography>

        {!shouldShowOutput && (
          <form onSubmit={formik.handleSubmit} className={classes.form}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <FormControl error={isError(formik, 'appKey')}>
                  <MultiSelectDropdown
                    value={formik.values.appKey}
                    label="App Key"
                    id="appKey"
                    handleChange={formik.handleChange('appKey')}
                    options={formattedAppKeys}
                  />
                  {isError(formik, 'appKey') && <FormHelperText>{formik.errors.appKey}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl error={isError(formik, 'startDate')}>
                  <DateTimePicker
                    label="Start Date"
                    id="startDate"
                    value={formik.values.startDate}
                    onChange={(event) => formik.setFieldValue('startDate', event)}
                    slots={{
                      inputAdornment: formik.values.startDate ? ClearableIconButton : undefined,
                    }}
                    slotProps={{
                      inputAdornment: {onClick: () => formik.setFieldValue('startDate', null)},
                    }}
                  />
                  {isError(formik, 'startDate') && <FormHelperText>{formik.errors.startDate}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl error={isError(formik, 'endDate')}>
                  <DateTimePicker
                    label="End Date"
                    id="endDate"
                    value={formik.values.endDate}
                    onChange={(event) => formik.setFieldValue('endDate', event)}
                    slots={{
                      inputAdornment: formik.values.endDate ? ClearableIconButton : undefined,
                    }}
                    slotProps={{
                      inputAdornment: {onClick: () => formik.setFieldValue('endDate', null)},
                    }}
                  />
                  {isError(formik, 'endDate') && <FormHelperText>{formik.errors.endDate}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item>
                <Button
                  name="preview"
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={formik.isSubmitting}
                  className={classes.button}
                >
                  {formik.isSubmitting ? <CircularProgress style={{color: 'inherit'}} size={24} /> : 'Preview'}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
        {errors.length > 0 && (
          <Grid container direction="column" spacing={1}>
            {errors.map((item, index) => (
              <Grid item key={index}>
                <Alert key={item} severity="error" data-testid={`error:${item}`}>
                  {item}
                </Alert>
              </Grid>
            ))}
          </Grid>
        )}
        {shouldShowOutput && errors.length === 0 && (
          <>
            <Grid
              container
              className={classes.optionBar}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
            >
              <Grid item></Grid>
              <Grid item>
                <TablePagination
                  component="div"
                  count={totalResults}
                  page={!totalResults || totalResults <= 0 ? 0 : page}
                  onPageChange={handlePageChange}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handlePageSizeChange}
                />
              </Grid>
            </Grid>
            <ContentSyncPreviewPagedList {...syncMap[ALL_OPTION]} />
            <Grid container spacing={2}>
              <Grid item>
                <Button name="done" variant="contained" color="primary" size="small" onClick={handleReset}>
                  Done
                </Button>
              </Grid>
              <Grid item>
                <Button name="exportImport" variant="outlined" color="primary" size="small" onClick={handleExport}>
                  Export for Import Tool
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </LocalizationProvider>
  );
}
