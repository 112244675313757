import React from 'react';
import {Navigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import auth from 'api/auth';

export default function RequireAuth({children}) {
  return auth.isLoggedIn() ? children : <Navigate to="/" replace />;
}

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};
