import React, {Fragment, useEffect, useState, useMemo, useCallback} from 'react';
import {useFormik} from 'formik';

import {makeStyles} from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {Alert, AlertTitle} from '@material-ui/lab';

import app from 'api/app';
import resources from 'api/resources';
import DocumentsViewerList from 'components/DocumentsViewer/DocumentsViewerList';
import Dropdown from 'components/Dropdown';
import {isError, getErrorMessageString} from 'lib/helper';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    height: '100%',
    padding: '30px 50px',
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: 500,
    marginBottom: 25,
  },
  submit: {
    padding: '13px 0px',
    textTransform: 'inherit',
    width: '100%',
    fontSize: 15,
    marginTop: 18,
  },
  subTitle: {
    color: '#757575',
    fontStyle: 'italic',
  },
  selectedAppLabel: {
    fontWeight: 600,
  },
  selectedAppKey: {
    color: '#757575',
    fontStyle: 'italic',
    fontSize: '0.8em',
  },
  loadingError: {
    marginTop: '20px',
  },
}));

export default function DocumentsViewer() {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [appLoadingError, setAppLoadingError] = useState(null);
  const [appKeys, setAppKeys] = useState([]);
  const [selectedAppKey, setSelectedAppKey] = useState('');

  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const [documentLoadingError, setDocumentLoadingError] = useState(null);
  const [appDocuments, setAppDocuments] = useState([]);

  // Load all available app keys
  useEffect(() => {
    let abort = false;

    (async () => {
      setIsLoading(true);
      setAppKeys([]);

      try {
        const appKeys = await app.getSageApps();
        if (abort) {
          return;
        }

        if (!appKeys || appKeys.length === 0) {
          throw new Error('No app keys found');
        }

        setAppKeys(appKeys);
      } catch (err) {
        setAppLoadingError(getErrorMessageString(err));
      }

      setIsLoading(false);
    })();

    return () => {
      abort = true;
    };
  }, []);

  useEffect(() => {
    if (!selectedAppKey) {
      return;
    }
    let abort = false;

    setIsLoadingDocuments(true);
    setDocumentLoadingError(null);
    setAppDocuments([]);

    resources
        .getResourceList(selectedAppKey)
        .then((resources) => {
          if (abort) {
            return;
          }

          // Convert the relevant Resources data into flat objects representing Documents
          const documents = resources.map((resource) => ({
            id: resource.Version.id,
            master_name: resource.ResourceIdentity.master_name,
            carrier: resource.ResourceIdentity.carrier,
            effective_date: resource.Version.effective_date ? new Date(resource.Version.effective_date) : null,
            resource_type: resource.ResourceIdentity.resource_type,
            resource_created_at: resource.Version.created_at ? new Date(resource.Version.created_at) : null,
            document_created_at: resource.Document.created_at ? new Date(resource.Document.created_at) : null,
            document_updated_at: resource.Document.updated_at ? new Date(resource.Document.updated_at) : null,
          }));

          if (documents.length === 0) {
            setDocumentLoadingError('No Documents found for app');
          }

          setAppDocuments(documents);
          setIsLoadingDocuments(false);
        })
        .catch((err) => {
          setDocumentLoadingError(getErrorMessageString(err));
          setIsLoadingDocuments(false);
        });

    return () => {
      abort = true;
      setIsLoadingDocuments(false);
      setAppDocuments([]);
    };
  }, [selectedAppKey]);

  const formattedAppKeys = useMemo(() => {
    return appKeys
        .map((app) => ({
          key: app.app_id,
          value: app.app_id,
          label: app.description,
        }))
        .sort((a, b) => a.value.localeCompare(b.value));
  }, [appKeys]);

  const selectedAppLabel = useMemo(() => {
    if (!selectedAppKey || !formattedAppKeys || !formattedAppKeys.length) {
      return null;
    }

    const appData = formattedAppKeys.find(({key}) => key === selectedAppKey);
    if (!appData) {
      return null;
    }

    return appData.label || appData.key;
  }, [selectedAppKey, formattedAppKeys]);

  const onSubmitValidator = useCallback(({appKey}) => {
    const errors = {};
    if (!appKey) {
      errors.appKey = 'Select an App';
    }
    return errors;
  }, []);

  // NOTE: This function _must_ be async for formik to catch any thrown errors correctly
  const onSubmitHandler = useCallback(
      async (values) => {
        setSelectedAppKey(values.appKey);
      },
      [setSelectedAppKey],
  );

  const formik = useFormik({
    initialValues: {appKey: ''},
    validate: onSubmitValidator,
    onSubmit: onSubmitHandler,
  });

  const handleReset = () => {
    formik.resetForm();
    setSelectedAppKey('');
    setAppDocuments([]);
    setDocumentLoadingError(null);
    setIsLoadingDocuments(false);
  };

  if (isLoading) {
    return (
      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  if (isLoadingDocuments) {
    return (
      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}>
        <Grid container direction="column" spacing={3} alignItems="center">
          <Grid item>Loading documents, this may take a few minutes</Grid>
          <Grid item>
            <CircularProgress color="primary" />
          </Grid>
        </Grid>
      </Backdrop>
    );
  }

  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h1">
        Documents Viewer
      </Typography>
      <Typography variant="subtitle1" className={classes.subTitle}>
        View select fields of documents assigned to an app
      </Typography>

      {selectedAppKey && !documentLoadingError && appDocuments && appDocuments.length ? (
        <Fragment>
          <Divider />
          <DocumentsViewerList
            tableTitle={
              <Grid item container direction="row" spacing={1} alignItems="center">
                <Grid item className={classes.selectedAppLabel}>
                  {selectedAppLabel}
                </Grid>
                <Grid item className={classes.selectedAppKey}>
                  ({selectedAppKey})
                </Grid>
              </Grid>
            }
            initialDocuments={appDocuments}
            appKey={selectedAppLabel}
          />
          <Grid container>
            <Grid item>
              <Button name="done" variant="outlined" color="primary" size="small" onClick={handleReset}>
                Done
              </Button>
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <FormControl>
            <Dropdown
              value={formik.values.appKey}
              label="App Key"
              id="appKey"
              handleChange={formik.handleChange('appKey')}
              helperText={formik.errors.appKey}
              isError={isError(formik, 'appKey')}
              options={formattedAppKeys}
            />
          </FormControl>
          <Button
            name="select app"
            type="submit"
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting}
            className={classes.submit}
          >
            {formik.isSubmitting ? <CircularProgress style={{color: 'inherit'}} size={24} /> : 'Select App'}
          </Button>

          {appLoadingError && (
            <Alert severity="error" className={classes.loadingError} data-testid={`error:${appLoadingError}`}>
              <AlertTitle>Error Loading Apps</AlertTitle>
              {appLoadingError}
            </Alert>
          )}
          {documentLoadingError && (
            <Alert severity="error" className={classes.loadingError} data-testid={`error:${documentLoadingError}`}>
              <AlertTitle>Error Loading Documents</AlertTitle>
              {documentLoadingError}
            </Alert>
          )}
        </form>
      )}
    </div>
  );
}
