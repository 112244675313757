import * as jwt from 'jsonwebtoken';
import fetch from 'api/fetch';
import user from 'api/user';
import {buildQueryString} from 'api/utils';

const STORAGE_TO_CLEAR = ['token', 'refreshToken'];

class Auth {
  /** Get the stored user access token */
  getToken() {
    return sessionStorage.getItem('token');
  }

  /** Get the stored user refresh token */
  getRefreshToken() {
    return sessionStorage.getItem('refreshToken');
  }

  /** Set both access and refresh token in one go */
  // This tool can be used to do potentially "dangerous operations" like running doc sync
  // therefore we're using sessionStorage to force the user to log back in when the page session ends
  setStoredTokens(token, refreshToken) {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('refreshToken', refreshToken);
  }

  /**  Check if the user is logged by validating the token */
  isLoggedIn() {
    const token = this.getToken();
    const refreshToken = this.getRefreshToken();
    if (!token || !refreshToken) {
      return false;
    }

    return this.validate(refreshToken);
  }

  /** Get the user's primary ID from their token */
  getUserPrimaryID() {
    const token = this.getToken();

    if (token) {
      const decoded = jwt.decode(token);
      return decoded.primary_user_id;
    }

    return null;
  }

  async updateToken(token, refreshToken) {
    // this is a re-log potentially to a different account, so we need to log out the existing session
    if (this.isLoggedIn()) {
      try {
        await this.logout();
      } catch (e) {
        console.error(`Error signing out existing session: ${e}`);
      }
    }

    this.setStoredTokens(token, refreshToken);
  }

  /** Log out the current user */
  async logout() {
    try {
      const response = await fetch(process.env.REACT_APP_AUTH_BASE_URL, '/token', 'DELETE', null);
      return response.data;
    } catch (errorResponse) {
      throw errorResponse.response.data;
    } finally {
      this.clearStorage();
    }
  }

  /** Clear the local storage variables */
  clearStorage() {
    for (let i = 0; i < STORAGE_TO_CLEAR.length; i++) {
      sessionStorage.removeItem(STORAGE_TO_CLEAR[i]);
    }
  }

  /** Determine authentication type (email/user_id+password or SSO) for the given email address */
  async getAuthType(primaryUserId) {
    const queryParams = {email: primaryUserId, primary_user_id: primaryUserId, client: 'internal-tools'};
    try {
      const response = await fetch(
          process.env.REACT_APP_AUTH_BASE_URL,
          '/user/auth_type' + buildQueryString(queryParams),
      );
      return response.data;
    } catch (errorResponse) {
      throw errorResponse.data;
    }
  }

  /** Log the user via password */
  async login({primaryUserId, password}) {
    const data = {};

    if (primaryUserId) {
      data['user_id'] = primaryUserId;
    }

    if (password) {
      data['password'] = password;
    }

    try {
      const tokenResponse = await fetch(process.env.REACT_APP_AUTH_BASE_URL, '/token', 'POST', data);
      const token = tokenResponse.data.token;
      const refreshToken = tokenResponse.data.refresh_token;
      await this.updateToken(token, refreshToken);
      await this.validatePronavSuperAdmin(primaryUserId);
    } catch (errorResponse) {
      if ('response' in errorResponse) {
        throw errorResponse.response.data;
      } else {
        throw errorResponse;
      }
    }
  }

  /* Checks if the current user is a Pronav Super Admin */
  async validatePronavSuperAdmin(primaryUserId) {
    try {
      const response = await user.getUserInfoByAppId(primaryUserId, 'sage-pronav');

      if (response.status !== 200 || response.data.role !== 'Super Admin') {
        throw new Error();
      }
    } catch {
      // The user is logged out if they can not be verified as a Pronav Super admin
      await this.logout();
      throw new Error('Access restricted to Pronav Super Admin\'s only');
    }
  }

  validate(token) {
    // Front-end should not control access to data so validating expiration claim is sufficient.
    // All data in the front-end is exposed to the client; the back-end must fully validate token
    // before taking action or releasing data.
    const decoded = jwt.decode(token);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  }

  /** Get the user's email from their token */
  getUserEmail() {
    const token = this.getToken();

    if (token) {
      const decoded = jwt.decode(token);
      return decoded.email;
    }

    return null;
  }
}

export default new Auth();
