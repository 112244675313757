export function downloadBlob(filename, content, contentType) {
  // Create a blob
  const blob = new Blob([content], {type: contentType});
  const url = URL.createObjectURL(blob);
  // Create a link to download it
  const element = document.createElement('a');
  element.href = url;
  element.setAttribute('download', filename);
  element.click();

  // Cleanup
  URL.revokeObjectURL(url);
  element.remove();
}

export const toCSV = (headers, rows, hasHeaderRow) => {
  // eslint-disable-next-line max-len
  //sourced from https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
  const rowTransformation = rows
      .map((row) => {
        const rowTransformed = [];
        headers.forEach((header) => {
          const formatter = header?.formatter ? header?.formatter : (x) => x;
          const value = formatter(row[header.field]);
          rowTransformed.push(value == null ? '""' : `"${value}"`);
        });
        return rowTransformed.join(',');
      })
      .join('\n');
  const csvContent = !hasHeaderRow
    ? rowTransformation
    : headers.map((header) => header.title).join(',') + '\n' + rowTransformation;

  return csvContent;
};
