import React, {Fragment} from 'react';
import {PropTypes} from 'prop-types';
import {makeStyles} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Accordion from '@material-ui/core/Accordion';
import Avatar from '@material-ui/core/Avatar';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiAlert-root': {
      marginBottom: 10,
    },
  },
  sectionCount: {
    fontSize: '0.8em',
  },
}));

export default function Output({errors = [], actions = [], warnings = []}) {
  const classes = useStyles();

  const renderSectionTitle = (title, count) => (
    <Grid container direction="row" alignItems="center" spacing={2}>
      <Grid item>
        <Typography variant="h6" component="h6">
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Avatar variant="rounded" className={classes.sectionCount}>
          {count}
        </Avatar>
      </Grid>
    </Grid>
  );

  const renderItems = (items, renderFunction) => {
    return (
      <Grid container direction="column" spacing={1}>
        {items.length === 0 && <Typography variant="body2">No results</Typography>}
        {items.map((item, index) => (
          <Grid item key={index}>
            {renderFunction(item)}
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderErrors = (errors) => {
    return renderItems(errors, renderError);
  };

  const renderError = (error) => {
    if (typeof error !== 'string') {
      return null;
    }

    return (
      <Alert key={error} severity="error" data-testid={`error:${error}`}>
        {error}
      </Alert>
    );
  };

  const renderWarnings = (warnings) => {
    return renderItems(warnings, renderWarning);
  };

  const renderWarning = (warning) => {
    if (typeof warning !== 'string') {
      return null;
    }

    return (
      <Alert key={warning} severity="warning" data-testid={`warning:${warning}`}>
        {warning}
      </Alert>
    );
  };

  const renderActions = (actions) => {
    return renderItems(actions, renderAction);
  };

  const renderAction = (action) => {
    const isLink = (action) => typeof action === 'object' && action.type === 'link';
    if (typeof action !== 'string' && !isLink(action)) {
      return null;
    }
    return (
      <Alert key={action} severity="info" data-testid={`action:${action}`}>
        {typeof action === 'string' ? (
          action
        ) : (
          <Fragment>
            {action.label}
            <a key={action.value} href={action.value} target="_blank" rel="noreferrer">
              {action.value}
            </a>
          </Fragment>
        )}
      </Alert>
    );
  };

  if (errors.length === 0 && actions.length === 0 && warnings.length === 0) {
    return <Alert severity="info">No changes detected</Alert>;
  }

  return (
    <div className={classes.root}>
      <Accordion defaultExpanded={errors.length > 0} TransitionProps={{timeout: 500}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.heading}>
          {renderSectionTitle('Errors', errors.length)}
        </AccordionSummary>
        <AccordionDetails>{renderErrors(errors)}</AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={warnings.length > 0} TransitionProps={{timeout: 500}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.heading}>
          {renderSectionTitle('Warnings', warnings.length)}
        </AccordionSummary>
        <AccordionDetails>{renderWarnings(warnings)}</AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={actions.length > 0 && errors.length === 0} TransitionProps={{timeout: 500}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.heading}>
          {renderSectionTitle('Actions', actions.length)}
        </AccordionSummary>
        <AccordionDetails>{renderActions(actions)}</AccordionDetails>
      </Accordion>
    </div>
  );
}

Output.propTypes = {
  errors: PropTypes.array,
  actions: PropTypes.array,
  warnings: PropTypes.array,
};
