import React, {useState, useCallback, useMemo, useEffect} from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import escapeRegExp from 'lodash.escaperegexp';

import {makeStyles, alpha} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';

import VirtualizedList from 'components/Common/VirtualizedList';
import {getErrorMessageString} from 'lib/helper';
import user from 'api/user';

import UserListItem from 'components/DeleteUsers/UserListItem';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '10px 0px',
  },
  filterInput: {
    width: '100%',
  },
  listContainer: {
    flexGrow: 1,
  },
  tableRow: {
    '&:hover': {
      background: alpha(theme.palette.primary.main, 0.2),
    },
  },
  tableRowOdd: {
    background: alpha(theme.palette.primary.main, 0.05),
  },
  mainGridColumn: {
    width: '100%',
    maxWidth: '20%',
  },
  smallGridColumn: {
    width: '100%',
    maxWidth: '10%',
  },
  idColumn: {
    width: '100%',
    maxWidth: '40%',
  },
  pagination: {
    borderBottom: 'none',
  },
}));

const ROW_HEIGHT = 100;

export default function UserList({tableTitle, initialUsers, selectedAppKey, handleReset, onDelete}) {
  const classes = useStyles();

  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [filterValue, setFilterValue] = useState('');

  const [pageUsers, setPageUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  useEffect(() => {
    const users = initialUsers.slice();
    users.sort((a, b) => {
      return `${a.lastName}${a.firstName}`.localeCompare(`${b.lastName}${b.firstName}`);
    });
    setUsers(users);
  }, [initialUsers, setUsers]);

  // PAGING
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // END OF PAGING

  const setDeletionState = useCallback(
      async (event, primaryUserId) => {
        try {
          setSelectedUsers((currentlySelected) => {
            const index = users.findIndex((a) => a.id === primaryUserId);
            if (index < 0) {
              return currentlySelected;
            }

            const newSelected = currentlySelected.slice();
            if (event.target.checked) {
              newSelected.push(users[index]);
            } else {
              const removalIndex = newSelected.findIndex((a) => a.id === primaryUserId);
              if (removalIndex < 0) {
                return currentlySelected;
              }
              newSelected.splice(removalIndex, 1);
            }

            return newSelected;
          });
        } catch (err) {
          console.log(`Error updating ${primaryUserId}: ${getErrorMessageString(err)}`);
        }
      },
      [setSelectedUsers, users],
  );

  //Update function, make sure its good then done!
  const deleteUsers = useCallback(async () => {
    const allFailed = [];
    for (const currentUser of selectedUsers) {
      if (currentUser.id === 'admin@pronavigator.ai') {
        continue;
      }
      try {
        await user.deleteUser(selectedAppKey, currentUser.id);
      } catch (error) {
        allFailed.push({
          id: currentUser.id,
          db: 'dialog',
        });
      }
      try {
        await user.deleteMembership(currentUser.id);
      } catch (error) {
        allFailed.push({
          id: currentUser.id,
          db: 'auth',
        });
      }
    }
    if (Object.keys(allFailed).length !== 0) {
      let formattedErrorMessage = '';
      for (const dict of allFailed) {
        formattedErrorMessage += `Error deleting User: ${dict['id']} from ${dict['db']}\n`;
      }
      alert(formattedErrorMessage);
    }
    setSelectedUsers([]);
    onDelete();
  }, [setSelectedUsers, onDelete, selectedUsers, selectedAppKey]);

  const displayAccounts = useMemo(() => {
    if (!users || !filterValue) {
      return users;
    }

    const filterExpressionString = filterValue
        .replace(/\s+/g, ' ')
        .split(' ')
        .map((part) => escapeRegExp(part))
        .join('.*');

    const filterExpression = new RegExp(filterExpressionString, 'gi');

    return users.filter((person) => filterExpression.test(person.id) || filterExpression.test(person.firstName));
  }, [users, filterValue]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFilterValueChange = useCallback(
      debounce((event) => {
        setFilterValue(event.target.value);
      }, 600),
      [setFilterValue],
  );

  useEffect(() => {
    const _pageUsers = displayAccounts.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
    setPageUsers(_pageUsers);
  }, [displayAccounts, rowsPerPage, page]);

  useEffect(() => {
    setPage(0);
  }, [displayAccounts]);

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>{tableTitle}</Grid>
        <Grid item>
          <TextField
            label="Filter By Name"
            variant="outlined"
            size="small"
            margin="dense"
            className={classes.filterInput}
            onChange={handleFilterValueChange}
            data-testid="archived-documents-list-filter-input"
          />
        </Grid>
      </Grid>
      <Grid item className={classes.listContainer}>
        <VirtualizedList
          items={pageUsers}
          rowHeight={ROW_HEIGHT}
          header={
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={1}
              className={classes.tableHeader}
              data-testid="archived-documents-list-header"
            >
              <Grid item className={classes.smallGridColumn} justifyContent="center">
                <Typography variant="body2">Select</Typography>
              </Grid>
              <Grid item className={classes.idColumn}>
                <Typography variant="body ">User Identification</Typography>
              </Grid>
              <Grid item className={classes.mainGridColumn}>
                <Typography variant="body2">Full Name</Typography>
              </Grid>
              <Grid item className={classes.mainGridColumn}>
                <Typography variant="body2">Email</Typography>
              </Grid>
            </Grid>
          }
          rowTemplate={({index, item, style, key}) => (
            <UserListItem
              key={key}
              style={style}
              classes={classes}
              index={index}
              account={item}
              setDeletionState={setDeletionState}
              isSelected={selectedUsers.find((i) => i.id === item.id) !== undefined}
            />
          )}
        />
      </Grid>
      <Grid container justifyContent="center">
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          count={displayAccounts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={classes.pagination}
        />
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <Button name="cancel" variant="outlined" color="primary" size="small" onClick={handleReset}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button name="delete" variant="outlined" color="primary" size="small" onClick={deleteUsers}>
            Delete
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

const userShape = {
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
};

UserList.propTypes = {
  tableTitle: PropTypes.node,
  initialUsers: PropTypes.arrayOf(PropTypes.shape(userShape)).isRequired,
  selectedAppKey: PropTypes.string.isRequired,
  handleReset: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
