import fetch from 'api/fetch';

const resourceTypes = {
  // Get the list of resource types
  getResourceTypes: async function() {
    const response = await fetch(process.env.REACT_APP_DIALOG_BASE_URL, '/sage-pronav/resource-types');
    return response.data.data.resource_types;
  },
  addResourceType: async function(resourceType) {
    await fetch(process.env.REACT_APP_DIALOG_BASE_URL, '/management/resource-types', 'POST', resourceType);
  },
  editResourceType: async function(id, resourceType) {
    await fetch(process.env.REACT_APP_DIALOG_BASE_URL, `management/resource-types/${id}`, 'PUT', resourceType);
  },
};

export default resourceTypes;
