import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import auth from 'api/auth';
import {
  Button,
  AppBar,
  Box,
  Toolbar,
  Typography,
  makeStyles,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import logo from 'static/pronavigator-logo.svg';
import PAGES from 'lib/pages';
import {HEADER_HEIGHT} from 'lib/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    height: HEADER_HEIGHT,
  },
  headerText: {
    flexGrow: 1,
  },
  logo: {
    width: '50px',
    marginRight: '10px',
    background: 'white',
    borderRadius: '5px',
  },
  header: {
    color: 'white',
    backgroundColor: 'black',
  },
}));

export default function Header() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);

  const renderListItems = () => {
    const listItem = (value) => {
      const Icon = value.icon;
      return (
        <ListItem
          button
          key={value.title}
          onClick={() => {
            navigate(value.route);
            toggleNavMenuVisibility();
          }}
          selected={true}
          >
          <ListItemIcon>
            <Icon className={classes.header} />
          </ListItemIcon>
          <ListItemText primary={value.title} />
        </ListItem>
      );
    };
    return <List>{Object.values(PAGES).map((value) => listItem(value))}</List>;
  };

  const onLogoutClick = async (e) => {
    try {
      e.preventDefault();

      await auth.logout();
      navigate('/');
    } catch (error) {
      console.error(error.message);
    }
  };

  const toggleNavMenuVisibility = (e) => {
    setIsNavMenuOpen(!isNavMenuOpen);
  };

  return (
    <header data-testid="pronav-header" className={classes.root}>
      <Box>
        <AppBar position="static" className={classes.header}>
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" onClick={toggleNavMenuVisibility} edge="start">
              <MenuIcon />
            </IconButton>

            <Drawer
              anchor="left"
              open={isNavMenuOpen}
              onClose={toggleNavMenuVisibility}
              classes={{paper: classes.header}}
            >
              {renderListItems()}
            </Drawer>

            <img src={logo} alt="Sage" className={classes.logo} />
            <Typography variant="h6" component="h1" className={classes.headerText}>
              ProNavigator Internal Tools
            </Typography>
            <Button onClick={onLogoutClick} color="inherit" variant="outlined">
              Logout
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
    </header>
  );
}

Header.propTypes = {
  onPageChange: PropTypes.func,
};
