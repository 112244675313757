import React from 'react';
import {makeStyles} from '@material-ui/core';

import PropTypes from 'prop-types';
import Header from 'components/Header';
import ProductionAlert from 'components/ProductionAlert';
import {HEADER_HEIGHT, PRODUCTION_ALERT_HEIGHT} from 'lib/constants';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
    boxSizing: 'border-box',
  },
  bodyContainer: {
    height: `calc(100% - ${HEADER_HEIGHT})`,
  },
  bodyContainerProduction: {
    height: `calc(100% - ${HEADER_HEIGHT} - ${PRODUCTION_ALERT_HEIGHT})`,
  },
}));

function renderDocInfo(docURL) {
  if (!docURL) return;

  return (
    <Alert severity="info">
      <a href={docURL} target="_blank" rel="noopener noreferrer">
        Click here
      </a>{' '}
      to view helpful documentation
    </Alert>
  );
}

export default function PageLayout({children, docURL}) {
  const classes = useStyles();

  // We are not checking NODE_ENV because it is always set to production for a build (including staging builds)
  const isProduction = process.env.REACT_APP_DIALOG_BASE_URL === 'https://api.pronavigator.ai/app';

  return (
    <div className={classes.root}>
      <Header />
      <ProductionAlert isProduction={isProduction} />
      <div className={isProduction ? classes.bodyContainerProduction : classes.bodyContainer}>
        {renderDocInfo(docURL)}
        {children}
      </div>
    </div>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  docURL: PropTypes.string,
};
