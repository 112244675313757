import React from 'react';
import {PropTypes} from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#deedf2',
  },
  headerCell: {
    fontWeight: 'bold',
  },
}));

const sortableCells = [
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'appId',
    numeric: false,
    disablePadding: false,
    label: 'App ID',
  },
  {
    id: 'desc',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
];

const nonSortableCells = [
  {
    id: 'lang',
    numeric: false,
    disablePadding: false,
    label: 'Languages',
  },
  {
    id: 'drt',
    numeric: false,
    disablePadding: false,
    label: 'Downloadables',
  },
  {
    id: 'prt',
    numeric: false,
    disablePadding: false,
    label: 'Printables',
  },
  {
    id: 'lob',
    numeric: false,
    disablePadding: false,
    label: 'Lines of Business',
  },
];

export default function AppTableHead(props) {
  const classes = useStyles();
  const {order, orderBy, onRequestSort} = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className={classes.header}>
        {sortableCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.headerCell}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        {nonSortableCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.headerCell}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell key="edit" className={classes.headerCell} align="center"></TableCell>
      </TableRow>
    </TableHead>
  );
}

AppTableHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};
