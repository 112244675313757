// Compares objects by the attribute orderBy
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 * Gets an object comparator function to compare in either ascending or descending order
 * @param {String} order The direction of the comparator (either 'asc' or 'desc')
 * @param {String} orderBy The attribute (of the objects) being compared
 * @returns {Function} An comparator function that compares objects
 */
export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * Sorts an array, maintaining the original order for equivalent elements
 * @param {Array} arr The array to be sorted
 * @param {String} comparator A standard comparator function returning positive and negative integers
 * @returns {Function} A copy of the array that is sorted
 */
export function stableSort(arr, comparator) {
  const stabilizedThis = arr.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
