import fetch from 'api/fetch';
import {buildQueryString} from 'api/utils';

const resources = {
  getAuthors: async function() {
    const response = await fetch(process.env.REACT_APP_DIALOG_BASE_URL, '/resources/authors');
    return response.data.authors;
  },

  /** Get the list of resources for the app */
  getResourceList: async function(
    appKey,
    {
      skipDownloadInfo = true,
      states = null,
      carrier = null,
      resourceType = null,
      language = null,
      offset = 0,
      limit = 2000,
    } = {},
  ) {
    try {
      let hasMore = true;
      let resources = [];
      while (hasMore) {
        const queryParams = {
          skip_download_info: skipDownloadInfo ? 'true' : 'false',
          carrier: carrier,
          state: states ? JSON.stringify(states) : null,
          resource_type: resourceType,
          full_response: true,
          language: language,
          offset: offset,
          limit: limit,
        };
        const response = await fetch(
            process.env.REACT_APP_DIALOG_BASE_URL,
            `${appKey}/resources/documents${buildQueryString(queryParams)}`,
        );
        const responseData = response.data;
        resources = resources.concat(responseData.results);
        hasMore = responseData.has_more;
        offset = responseData.offset;
      }

      return resources;
    } catch (errorResponse) {
      throw errorResponse.data || errorResponse.message;
    }
  },

  /**
   * Update mutable properties of a specific Document
   * @param {String} appKey     - The key of the app the Document belongs to
   * @param {Number} documentID - The ID of the Document to be updated
   * @param {Object} data       - An object containing the properties & their new values to be updated
   * @returns {Object} The updated representation of the Document
   */
  async updateDocument(appKey, documentID, data) {
    try {
      const response = await fetch(
          process.env.REACT_APP_DIALOG_BASE_URL,
          `${appKey}/resources/document/${documentID}`,
          'POST',
          data,
      );
      return response.data;
    } catch (errorResponse) {
      throw errorResponse.data;
    }
  },

  async createAuthor(data) {
    try {
      const response = await fetch(process.env.REACT_APP_DIALOG_BASE_URL, '/management/authors', 'POST', data);
      return response.data;
    } catch (errorResponse) {
      console.error(errorResponse);
      throw errorResponse.data;
    }
  },

  /**
   * Update mutable properties of a specific Document
   * @param {Number} authorID - The ID of the Document to be updated
   * @param {Object} data       - An object containing the properties & their new values to be updated
   * @returns {Object} The updated representation of the Document
   */
  async updateAuthor(authorID, data) {
    try {
      const response = await fetch(
          process.env.REACT_APP_DIALOG_BASE_URL,
          `/management/authors/${authorID}`,
          'PUT',
          data,
      );
      return response.data;
    } catch (errorResponse) {
      throw errorResponse.data;
    }
  },
};

export default resources;
