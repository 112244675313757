import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const DocumentsViewerListItem = React.memo(function DocumentsViewerListItem({
  style,
  classes,
  index,
  document,
  dataConfig,
}) {
  const isOddRow = index % 2 === 1;

  const Cell = ({field, className, cellClassName, formatter}) => {
    const text = formatter ? formatter(document[field]) : document[field];

    return (
      <Grid item container alignItems="center" className={clsx(className, cellClassName)}>
        <Tooltip placement="bottom-start" classes={{tooltip: classes.xlTooltip}} title={text}>
          <Typography variant="body2" noWrap>
            {text}
          </Typography>
        </Tooltip>
      </Grid>
    );
  };
  Cell.propTypes = {
    field: PropTypes.string,
    className: PropTypes.string,
    cellClassName: PropTypes.string,
    formatter: PropTypes.func,
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={clsx(classes.tableRow, isOddRow && classes.tableRowOdd)}
      style={style}
      data-testid={`document-viewer-list-row:${document.id}`}
      >
      {dataConfig.map((config, configIndex) => (
        <Cell {...config} key={`document:${document.id}-column:${configIndex}`} />
      ))}
    </Grid>
  );
});

DocumentsViewerListItem.propTypes = {
  style: PropTypes.object,
  classes: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  document: PropTypes.object.isRequired,
  dataConfig: PropTypes.array,
};

export default DocumentsViewerListItem;
