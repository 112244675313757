import {
  ListAlt,
  Label,
  ViewList,
  Person,
  Visibility,
  VerticalSplit,
  Description,
  Apps,
  Business,
  PersonAdd,
  CloudDone,
} from '@material-ui/icons';

const PAGES = {
  GOOGLE_SHEET_GENERATION: {
    title: 'Google Sheet Generation',
    route: '/google-sheet-generation',
    docURL: 'https://pronavtechnologies.atlassian.net/wiki/spaces/ENG/pages/1498742785',
    icon: ListAlt,
  },
  DOCUMENTS_VIEWER: {
    title: 'Documents Viewer',
    route: '/documents-viewer',
    docURL: '',
    icon: Visibility,
  },
  LABEL_SYNC: {
    title: 'Label Sync',
    route: '/label-sync',
    docURL: '',
    icon: Label,
  },
  DOCUMENT_MANAGEMENT: {
    title: 'Document Management',
    route: '/document-management',
    docURL: 'https://pronavtechnologies.atlassian.net/l/c/vJ131BPV',
    icon: ViewList,
  },
  DELETE_USERS: {
    title: 'Delete Users',
    route: '/delete-users',
    docURL: 'https://pronavtechnologies.atlassian.net/l/cp/AU251tER',
    icon: Person,
  },
  CONTENT_SYNC_PREVIEW: {
    title: 'Content Sync Import Preview',
    route: '/content-sync-preview',
    docURL: '',
    icon: CloudDone,
  },
  LINE_OF_BUSINESS_MANAGEMENT: {
    title: 'Line of Business Management',
    route: '/Lineofbusiness-management',
    docURL: '',
    icon: Business,
  },
  REGION_MANAGEMENT: {
    title: 'Region Management',
    route: '/region-management',
    docURL: '',
    icon: VerticalSplit,
  },
  RESOURCE_TYPE_MANAGEMENT: {
    title: 'Resource Type Management',
    route: '/resource-type-management',
    docURL: '',
    icon: Description,
  },
  APP_MANAGEMENT: {
    title: 'App Management',
    route: '/app-management',
    docURL: '',
    icon: Apps,
  },
  AUTHOR_MANAGEMENT: {
    title: 'Author Management',
    route: '/author-management',
    docURL: '',
    icon: PersonAdd,
  },
};

export default PAGES;
