import {downloadBlob, toCSV} from 'lib/utils';
import contentSync from 'api/contentSync';

export const ALL_OPTION = 'All';
export const USER_SYNC_OPTION = 'User';
export const GROUP_SYNC_OPTION = 'Group';
export const SHARED_SYNC_OPTION = 'Shared';
export const NEW_FILENAME_COLUMN_INDEX = 19;

export const mapContentSyncMetadata = (contentSyncMetadata) => {
  const mappedContentSyncMetadata = {
    [USER_SYNC_OPTION]: [],
    [GROUP_SYNC_OPTION]: [],
    [SHARED_SYNC_OPTION]: [],
  };
  contentSyncMetadata.forEach((entry) => {
    mappedContentSyncMetadata[entry.access_type].push(entry);
  });
  return mappedContentSyncMetadata;
};

export async function previewContentSyncMetadataExport({
  appKeys = null,
  startDate = null,
  endDate = null,
  pageSize,
  syncType,
  mapContentSyncMetadata,
}) {
  const response = await contentSync.previewContentSyncMetadata({appKeys, startDate, endDate, pageSize});

  const recordsRetrieved = response.data.records;
  const recordsRetrievedCount = response.data.records.length;
  const totalCount = response.data.total_count;

  let totalRetrievedCount = 0;
  totalRetrievedCount += recordsRetrievedCount;

  const recordsToProcess = [...recordsRetrieved];

  while (totalRetrievedCount < totalCount) {
    try {
      const nextResponse = await contentSync.previewContentSyncMetadata({
        appKeys,
        startDate,
        endDate,
        pageSize,
        offset: totalRetrievedCount,
      });
      const nextRecordsRetrieved = nextResponse.data.records;
      const nextRecordsRetrievedCount = nextResponse.data.records.length;

      if (nextRecordsRetrieved === [] || nextRecordsRetrievedCount === 0) {
        break;
      }

      totalRetrievedCount += nextRecordsRetrievedCount;

      recordsToProcess.push(...nextRecordsRetrieved);
    } catch (error) {
      console.error('Error when attempting to retrieve additional content sync metadata.');
      break;
    }
  }

  const csv = createCSVContentSyncPreviewMetadataExport(mapContentSyncMetadata, recordsToProcess, syncType);
  return downloadBlob(`${new Date().toISOString()}-${syncType}.csv`, csv, 'text/csv;charset=utf-8;');
}

export function createCSVContentSyncPreviewMetadataExport(mapContentSyncMetadata, recordsToProcess, syncType) {
  const headers = Array(NEW_FILENAME_COLUMN_INDEX - 1)
      .fill({title: ''})
      .concat([{title: '', field: 'new_filename'}]);

  const mappedRows = mapContentSyncMetadata(recordsToProcess);

  const formattedRows = [];
  const userOptionRows = mappedRows[USER_SYNC_OPTION];
  const groupOptionRows = mappedRows[GROUP_SYNC_OPTION];
  const sharedOptionRows = mappedRows[SHARED_SYNC_OPTION];
  switch (syncType) {
    case ALL_OPTION:
      userOptionRows.forEach((row) => formattedRows.push({new_filename: row['bucket_key']}));
      groupOptionRows.forEach((row) => formattedRows.push({new_filename: row['bucket_key']}));
      sharedOptionRows.forEach((row) => formattedRows.push({new_filename: row['bucket_key']}));
      break;
    case USER_SYNC_OPTION:
      userOptionRows.forEach((row) => formattedRows.push({new_filename: row['bucket_key']}));
      break;
    case GROUP_SYNC_OPTION:
      groupOptionRows.forEach((row) => formattedRows.push({new_filename: row['bucket_key']}));
      break;
    case SHARED_SYNC_OPTION:
      sharedOptionRows.forEach((row) => formattedRows.push({new_filename: row['bucket_key']}));
      break;
    default:
      break;
  }

  return toCSV(headers, formattedRows, false);
}
