import React, {useState, useEffect} from 'react';
import {makeStyles, Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import {linesOfBusiness, postLineOfBusiness, putLineOfBusiness, formatDate} from 'api/linesOfBusiness';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    height: '100%',
    padding: '30px 50px',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    padding: '13px 0px',
    textTransform: 'inherit',
    fontSize: 15,
    marginTop: 18,
    marginRight: 15,
    width: 250,
  },
  table: {
    minWidth: 650,
  },
  searchType: {
    minWidth: 120,
    marginRight: theme.spacing(2),
  },
  searchInput: {
    flexGrow: 1,
  },
}));

const headCells = [
  {id: 'id', label: 'ID'},
  {id: 'name', label: 'Name'},
  {id: 'displayName', label: 'Display Name'},
  {id: 'createdAt', label: 'Created At'},
  {id: 'updatedAt', label: 'Updated At'},
];

export default function LineOfBusinessManagement() {
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  const [newName, setNewName] = useState('');
  const [newDisplayName, setNewDisplayName] = useState('');

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const [editRowIndex, setEditRowIndex] = useState(null);
  const [orderBy, setOrderBy] = useState('id');
  const [order, setOrder] = useState('asc');
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [searchType, setSearchType] = useState('id');
  const [searchInput, setSearchInput] = useState('');

  const [nameError, setNameError] = useState('');
  const [displayNameError, setDisplayNameError] = useState('');

  // Function to validate input
  const validateInput = () => {
    let hasError = false;
    if (!newName.trim()) {
      setNameError('This field is required.');
      hasError = true;
    } else {
      setNameError('');
    }

    if (!newDisplayName.trim()) {
      setDisplayNameError('This field is required.');
      hasError = true;
    } else {
      setDisplayNameError('');
    }

    return !hasError;
  };

  // Function to handle add value confirmation
  const handleAddConfirmation = async () => {
    const isValidInput = validateInput();
    if (isValidInput) {
      // If the input is valid, proceed with adding the value
      await addValue(newName, newDisplayName);
      closeAddDialog(); // Close the dialog here
    }
  };

  // Function to handle Edit value confirmation
  const handleEditConfirmation = async () => {
    const isValidInput = validateInput();
    if (isValidInput) {
      // If the input is valid, proceed with adding the value
      await editValue(newName, newDisplayName);
      closeAddDialog(); // Close the dialog here
    }
  };
  const openAddDialog = () => {
    setIsAddDialogOpen(true);
  };

  const closeAddDialog = () => {
    setIsAddDialogOpen(false);
    setNewName('');
    setNewDisplayName('');
  };

  const openEditDialog = (index) => {
    // Calculate the correct index in the rows array
    const rowIndex = index + page * rowsPerPage;
    const rowToEdit = rows[rowIndex];
    setNewName(rowToEdit.name);
    setNewDisplayName(rowToEdit.displayName);
    setEditRowIndex(rowIndex); // Store the correct index
    setIsEditDialogOpen(true);
  };

  const closeEditDialog = () => {
    setIsEditDialogOpen(false);
    setNewName('');
    setNewDisplayName('');
    setEditRowIndex(null);
  };

  const addValue = async () => {
    try {
      const currentDate = formatDate(new Date());

      // Call the linesOfBusinessPOST function to create a new line of business
      const newRow = await postLineOfBusiness(rows, newName, newDisplayName);

      // Set createdAt and updatedAt values for the new row
      newRow.createdAt = currentDate;
      newRow.updatedAt = currentDate;

      // Update the state with the newly created row
      setRows([...rows, newRow]);

      // Close the add dialog
      closeAddDialog();
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error('Error creating new line of business:', error);
    }
  };

  const editValue = async () => {
    if (editRowIndex !== null) {
      try {
        const currentDate = formatDate(new Date());
        const editedRow = rows[editRowIndex];

        // Check if any values were actually changed by the user
        if (newName !== editedRow.name || newDisplayName !== editedRow.displayName) {
          // Call the linesOfBusinessPUT function to update the existing line of business
          const updatedRow = await putLineOfBusiness(editedRow.id, newName, newDisplayName);

          // Set updatedAt value for the updated row
          updatedRow.updatedAt = currentDate;

          // Set createdAt value for the updated row
          updatedRow.createdAt = editedRow.createdAt;

          // Update the state with the updated row
          const updatedRows = rows.map((row) => (row.id === updatedRow.id ? updatedRow : row));
          setRows(updatedRows);
        }
        // Close the edit dialog
        closeEditDialog();
      } catch (error) {
        // Handle any errors that occur during the API call
        console.error('Error updating line of business:', error);
      }
    }
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const filteredRows = rows.filter((row) => {
    if (searchInput === '') return true;

    const searchInputLower = searchInput.toLowerCase();

    switch (searchType) {
      case 'id':
        return row.id.toString().toLowerCase().includes(searchInputLower);
      case 'name':
        return (row.name || '').toLowerCase().includes(searchInputLower);
      case 'displayName':
        return (row.displayName || '').toLowerCase().includes(searchInputLower);
      case 'createdAt':
        return row.createdAt.toLowerCase().includes(searchInputLower);
      case 'updatedAt':
        return row.updatedAt.toLowerCase().includes(searchInputLower);
      default:
        return true;
    }
  });

  const sortedRows = filteredRows
      .sort((a, b) => {
        const isAsc = order === 'asc';
        const valueA = a[orderBy];
        const valueB = b[orderBy];

        if (valueA < valueB) {
          return isAsc ? -1 : 1;
        }
        if (valueA > valueB) {
          return isAsc ? 1 : -1;
        }
        return 0;
      })
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Fetch data from API when component mounts using useEffect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const rowsData = await linesOfBusiness();
        setRows(rowsData);
      } catch (error) {
        console.error('Error fetching data from API:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h1">
        Line of Business Management
      </Typography>

      <div className={classes.searchContainer}>
        <FormControl className={classes.searchType}>
          <InputLabel>Search Type</InputLabel>
          <Select value={searchType} onChange={handleSearchTypeChange}>
            <option value="id">ID</option>
            <option value="name">Name</option>
            <option value="displayName">Display Name</option>
            <option value="createdAt">Created At</option>
            <option value="updatedAt">Updated At</option>
          </Select>
        </FormControl>
        <TextField
          className={classes.searchInput}
          label="Search"
          value={searchInput}
          onChange={handleSearchInputChange}
        />
      </div>

      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id}>
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={() => handleSortRequest(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedRows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.displayName}</TableCell>
                <TableCell>{row.createdAt}</TableCell>
                <TableCell>{row.updatedAt}</TableCell>
                <TableCell>
                  <Button color="primary" onClick={() => openEditDialog(index)} disabled>
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <div>
        <Button className={classes.addButton} variant="contained" color="primary" onClick={openAddDialog} disabled>
          Add New Value
        </Button>
      </div>

      <Dialog open={isAddDialogOpen} onClose={closeAddDialog}>
        <DialogTitle>Add New Value</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            error={Boolean(nameError)}
            helperText={nameError}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Display Name"
            value={newDisplayName}
            onChange={(e) => setNewDisplayName(e.target.value)}
            error={Boolean(displayNameError)}
            helperText={displayNameError}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAddDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddConfirmation} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isEditDialogOpen} onClose={closeEditDialog}>
        <DialogTitle>Update Value</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            error={Boolean(nameError)}
            helperText={nameError}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Display Name"
            value={newDisplayName}
            onChange={(e) => setNewDisplayName(e.target.value)}
            error={Boolean(displayNameError)}
            helperText={displayNameError}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditConfirmation} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
